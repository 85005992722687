import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import {  GetURL} from '../service/gettingUrl.service';



import {PlatformLocation} from '@angular/common';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class BannerService{
    
        private url = '/public/api/bannerService/banners';
        urlValue: boolean;

        constructor(private http: HttpClient, private geturl: GetURL,private pl: PlatformLocation ) {
          geturl.setUrlValue((pl as any).location.href);
             this.urlValue =geturl.getUrlValue();
        }

        getBanners(){  
            return this.http.get(this.url)
            
        //     .map(this.extractList).catch( res => {
        //         return Observable.of(this.extractList(res))  
        // })

        }

        private extractList(res: Response){
            if(res.status == 200 && this.urlValue){}
                 return (res.status === 200 ? res.json(): []);
          }

        
            
}


        
        
