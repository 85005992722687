
import {of as observableOf,  Observable } from 'rxjs';

import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';



import { GetURL } from '../service/gettingUrl.service'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SuggesionService {
      private states: Array<string>;
      private url : string;
      urlValue: boolean; 

      constructor(private http: HttpClient, private getUrl: GetURL ) {
                this.urlValue= getUrl.getUrlValue();
                if(this.urlValue){
                  this.url="/admin/api/networkdevice/autosuggest";
                  }
                  else{
                    this.url="/public/api/networkdevice/autosuggest";
                  }
        }


        
      getResult(searchValue:string): Observable<any> {
        var suggestionInput = {
          "suggestionText":searchValue
        };
                return this.http.post(this.url,suggestionInput)
                // .pipe(
                // map(this.extractList),
                // catchError( res => {
                //   return observableOf(this.extractList(res))
                // }),)
      }



      private extractList(res: Response){
              return (res.status === 200 ? res.json(): []);
      }
}