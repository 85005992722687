import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nospace' })
export class InteropNospacePipe implements PipeTransform{
      transform(text: string){
            if(text== "OS Type"){
              return text.replace(/\s/g, '').charAt(0).toLowerCase()+text.replace(/\s/g, '').charAt(1).toLowerCase()+text.replace(/\s/g, '').slice(2);
            }
            else{
              return text.replace(/\s/g, '').charAt(0).toLowerCase()+text.replace(/\s/g, '').slice(1);
            }
      }
}