
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import {  InteropGetURL} from '../interop-service/interop-gettingUrl.service';



import {PlatformLocation} from '@angular/common';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class InteropBannerService{
    
        private url = '/public/api/bannerService/banners';
        urlValue: boolean;

        constructor(private http: HttpClient, private geturl: InteropGetURL,private pl: PlatformLocation ) {
          geturl.setUrlValue((pl as any).location.href);
             this.urlValue =geturl.getUrlValue();
        }

        getBanners(){  
            return this.http.get(this.url)
            
        //     .pipe(map(this.extractList),catchError( res => {
        //         return observableOf(this.extractList(res))  
        // }),)

        }

        private extractList(res: Response){
            if(res.status == 200 && this.urlValue){}
                 return (res.status === 200 ? res.json(): []);
          }

        
            
}


        
        
