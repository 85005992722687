<div class="filterSearch">
    <h6> Transceiver Product ID Filters</h6>
    <ul class="filters">
        <li class="collapsed" *ngFor="let model of jData?.filters;let i = index">
            <h6 *ngIf="model.name === 'Breakout Patchcord Required'" style="padding: 20px 0px 10px;"> Transceiver Interoperability Filters</h6>
            <a class="link title" *ngIf="getCookies(model.name) && model.values.length>0 else filterHeading1" class="icon-remove-outline name" (click)="toggle($event,model.name,i)" style="color: #6abf4b;"><span *ngIf="model.name !== 'Reach'" class="disabled" >{{model.name | uppercase}}
            </span>
            <span *ngIf="model.name === 'Reach'" class="disabled" >{{model.name | uppercase}} (up to)
            </span></a>
            <a class="link title" *ngIf="getCookies(model.name)== false && model.values.length>0 else filterHeading2" class="icon-add-outline name" (click)="toggle($event,model.name,i)"  style="color: #6abf4b;"><span *ngIf="model.name !== 'Reach'" class="disabled" >{{model.name | uppercase}}
            </span>
            <span *ngIf="model.name === 'Reach'" class="disabled" >{{model.name | uppercase}} (up to)
            </span></a>
            <ng-template #filterHeading1>
                <a class="link title" *ngIf="getCookies(model.name)" class="icon-add-outline name disabled" style="color: #6abf4b;" ><span *ngIf="model.name !== 'Reach'" class="disabled" >{{model.name | uppercase}}
                    </span>
                    <span *ngIf="model.name === 'Reach'" class="disabled" >{{model.name | uppercase}} (up to)
                    </span></a>
            </ng-template>
            <ng-template #filterHeading2>
                <a class="link title" *ngIf="getCookies(model.name)== false" class="icon-add-outline name disabled" style="color: #6abf4b;"><span *ngIf="model.name !== 'Reach'" class="disabled" >{{model.name | uppercase}}
                    </span>
                    <span *ngIf="model.name === 'Reach'" class="disabled" >{{model.name | uppercase}} (up to)
                    </span></a>
            </ng-template>
            <ul *ngIf= "getCookies(model.name)" class="filterlist" id="{{ model.name | nospace}}" style="display: block;">
                <input name="term" class="textbox" placeholder="Search.." *ngIf="model.values.length>4" id="{{ model.name | nospace}}" (keyup)="check($event,model.values,model.name)">
                <div *ngIf="currentFilter === null || currentFilter != model.name">
                    
                    <li *ngFor="let value of model.values | slice:0:4">
                    <label class="checkbox" *ngIf="value.filterChecked">
              <input class="filtercheckbox" type="checkbox" [checked]=value.filterChecked id="{{value.id}}" class="{{model.name | lowercase }}" (change)="checked(model.name,value,$event)">
              <span class="checkbox__input"></span>
              <span class="checkbox__label">{{value.name || "NA" }}  </span> <span class="badge">{{value.count}}</span>
          </label>
                    </li>
                    <li *ngFor="let value of model.values | slice:0:4">
                        <label class="checkbox" *ngIf=!value.filterChecked>
                    <input  class="filtercheckbox" type="checkbox" [checked]=value.filterChecked id="{{value.id}}" class="{{model.name | lowercase }}" (change)="checked(model.name,value,$event)">
                    <span class="checkbox__input"></span>
                    <span class="checkbox__label">{{value.name || "NA" }}  </span> <span class="badge">{{value.count}}</span>
                </label>
                    </li>
                    <a *ngIf="model.values.length>4" class="show-more" data-toggle="modal" data-target="#showMoreModal" (click)="getData(model.values,model.name)" data-backdrop="false">
            +{{model.values.length-4}}&nbsp;more
        </a>
                </div>
                <div *ngIf="currentFilter === model.name" id="{{ model.name | nospace }}">
                    <li *ngFor="let value of content | slice:0:4">
                        <label class="checkbox" *ngIf="value.filterChecked">
                            <input class="filtercheckbox" type="checkbox" [checked]=value.filterChecked id="{{value.id}}" class="{{model.name | lowercase }}" (change)="checked(model.name,value,$event)">
                            <span class="checkbox__input"></span>
                            <span class="checkbox__label">{{value.name || "NA" }}  </span> <span class="badge">{{value.count}}</span>
                        </label>
                    </li>
                    <li *ngFor="let value of content | slice:0:4">
                        <label class="checkbox" *ngIf="!value.filterChecked">
                                <input class="filtercheckbox" type="checkbox" [checked]=value.filterChecked id="{{value.id}}" class="{{model.name | lowercase }}" (change)="checked(model.name,value,$event)">
                                <span class="checkbox__input"></span>
                                <span class="checkbox__label">{{value.name || "NA" }}  </span> <span class="badge">{{value.count}}</span>
                            </label>
                    </li>
                    <a href="#" *ngIf="content.length>4" class="show-more" data-toggle="modal" data-target="#showMoreModal" (click)="getData(content,model.name)" data-backdrop="false">
                    +{{content.length-4}}&nbsp;more
                    </a>
                </div>
            </ul>
            <div class="modal in {{model.name |lowercase}}"  id="showMoreModal" role="dialog" *ngIf="model.values.length === counter || model.values.length === length">
                <div class="modal-dialog modal-lg">
    
                    <div class="modal-content">
    
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" (click)="callSearch()">
                                        <h6 class="nameclose">Close To Continue&nbsp;<span aria-hidden="true" class="alert__close icon-close"></span></h6>
                                    </button>
                        </div>
                        <div class="modal-body">
                                <div class="alphabet" >
                                    <ul  class="alphalist" >
                                    <a  class="alphaitem" id={{alpha}} *ngFor="let alpha of alphabets" (click)="refine(alpha)">{{alpha}} &nbsp;&nbsp;</a>
                                    <a class = "alphaitem" id="ALL" (click)="refine('ALL')">ALL</a>
                                    </ul>
                                </div>
                            <ul class="panel">
                                <li *ngFor="let value of contents">
                                    <label class="checkbox" *ngIf=value.filterChecked>
                                        <input class="filtercheckbox" type="checkbox" [checked]=value.filterChecked id="{{value.id}}" 
                                        class="{{model.name | lowercase }}" (change)="checkedPopup(value,$event)">
                                        <span class="checkbox__input"></span>
                                        <span class="checkbox__label">{{value.name || "NA" }}  </span> <span class="badge">{{value.count}}</span>
                                    </label>
                                </li>
                                <li *ngFor="let value of contents">
                                    <label class="checkbox" *ngIf=!value.filterChecked>
                                        <input class="filtercheckbox" type="checkbox" [checked]=value.filterChecked id="{{value.id}}" 
                                        class="{{model.name | lowercase }}" (change)="checkedPopup(value,$event)">
                                        <span class="checkbox__input"></span>
                                        <span class="checkbox__label">{{value.name || "NA" }}  </span> <span class="badge">{{value.count}}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    </div>
    <!-- Modal -->