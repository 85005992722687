import { FilterState } from './interface.service';
import { resultState} from './interface.results';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Subject ,  Observable } from 'rxjs';


@Injectable()
export class DataService {
        data:Array<{}>;
        private messageSource = new BehaviorSubject<string>("default message");
        currentMessage = this.messageSource.asObservable();
        private filterSubject = new Subject<FilterState>();
        FilterState = this.filterSubject.asObservable();
        private removeSubject = new Subject<FilterState>();
        removeState = this.removeSubject.asObservable();
        

        constructor() {
             this.data=[];
        }

        addFilter(value:any,filtername:string) {
              value["filtername"]=filtername;
              this.filterSubject.next(<FilterState>value);
            //   this.data.push(value);
            }

        addToData(value:any,filtername:string){
            value["filtername"]=filtername;
            this.data.push(value);
        }

        removeFilter(value:any) {
              this.removeSubject.next(<FilterState>value); 
            }  


        changeMessage(message: string) {
              this.messageSource.next(message);
              //this.data=[{}];
        }
}