
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";



import { HttpClient } from "@angular/common/http";

@Injectable()
export class RouteInformationService{
    
        private url = '/public/api/networkdevice/route';
        response: any;

        constructor(private http: HttpClient) {
        }

        getRouteInfo(routeFilters: any){
            return this.http.post(this.url,routeFilters)
        //     .pipe(map(this.extractList),catchError( res => {
        //         return observableOf(this.extractList(res))  
        // }),)
        }

        private extractList(res: Response){
                 return (res.status === 200 ? res.json(): []);
          }        
}


        
        
