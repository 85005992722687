<div class = "row banner">
        <div class="alert alert--success" id="success1" *ngIf="showSuccessAdd">
                <div class="alert__icon icon-check"></div>
                <div class="alert__message">"<span [innerHTML] = "alertTitle"></span>" banner has been successfully created! You can check the same in the list of banners by switching to 'Exising Banners' tab.</div>
                <div class="alert__close icon-close" (click)="closeRes('success')"></div>
        </div>
        <div class="alert alert--success" id="success2" *ngIf="showSuccessEdit">
                <div class="alert__icon icon-check"></div>
                <div class="alert__message">"<span [innerHTML] = "alertTitle"></span>" banner has been successfully edited.</div>
                <div class="alert__close icon-close" (click)="closeRes('success')"></div>
        </div>
        <div class="alert alert--danger" id="delete" *ngIf="showSuccessDelete">
                <div class="alert__icon icon-trash"></div>
                <div class="alert__message">"<span [innerHTML] = "alertTitle"></span>" banner has been deleted.</div>
                <div class="alert__close icon-close" (click)="closeRes('success')"></div>
        </div>
        
        <h5 style="padding-bottom:20px"><b>TMG Banners</b></h5>
        <div class = "col-md-12 toggle">
            
            <div id='gfg'>
                <tabset>
                    <tab heading="Existing Banners">
                        <table class="table table--bordered table--responsive table--wrap">
                            <thead>
                                <tr>
                                    <th style="width:10%!important">Node</th>
                                    <th style="width:17.5%!important">Title</th>
                                    <th style="width:20%!important">Description</th>
                                    <th style="width:10%!important">Status</th>
                                    <th style="width:17.5%!important">Link</th>
                                    <th style="width:17.5%!important">Image</th>
                                    <th style="width:7.5%!important">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor = "let banner of banners, let i = index">
                                    <td style="width:10%!important">{{i+1}}</td>
                                    <td style="width:17.5%!important"><span [innerHTML] = "banner.title"></span></td>
                                    <td style="width:20%!important"><span [innerHTML] = "banner.description"></span></td>
                                    <td style="width:10%!important"><span *ngIf = "banner.enabled=='Y'">Active</span><span *ngIf = "banner.enabled=='N'">Inactive</span></td>
                                    <td style="width:17.5%!important"><span><a href = "{{banner.imageUrl}}">{{banner.imageUrl}}</a></span></td>
                                    <td style="width:17.5%!important"><img src = "data:image/jpeg;base64,{{banner.image_blob}}"></td>
                                    <td style="width:7.5%!important">
                                        <button type="button" class="edit-button" data-toggle="modal" data-target="#UploadFile" (click) = "populate(banner)">
                                            <span class="icon-edit"></span>
                                        </button>
                                        <button *ngIf = "banner.enabled=='Y'" type="button" class="delete-button" (click) = "deleteFile(banner.id,banner.title)">
                                            <span class="icon-trash"></span>
                                        </button>
                                        <button *ngIf = "banner.enabled=='N'" type="button" class="delete-button" disabled>
                                            <span class="icon-trash"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>           
                        </table>
                    </tab>
                    <tab heading="Add New Banners">
                        <form #f=ngForm>
                            <div class = "form-group">
                                <label style="font-size:11px" for="title">Title</label>
                                <input [(ngModel)]= "title" name="title" id="title" type="text" autocomplete="off" (keyup) = "blankSpaceCheck(title)" class = "form-control" required maxlength="85">
                            </div>
                            <div class = "form-group">
                                <label style="font-size:11px" for="description">Description</label>
                                <input [(ngModel)]= "description" name="description" id="description" type="text" autocomplete="off" (keyup) = "blankSpaceCheck(description)" class = "form-control" required maxlength="120">               
                            </div>
                            <div class = "form-group">
                                <label style="font-size:11px" for="link">Link</label>
                                <input [(ngModel)]= "imageUrl" name="imageUrl" id="imageUrl" type="text" autocomplete="off" (keyup) = "blankSpaceCheck(imageUrl)" class = "form-control" required>   
                            </div>
                            <div>
                            <p style="font-size:11px;font-weight:700!important">Choose Image:</p>
                            <div class = "col-md-12">
                            <input ngModel name = "selectefile" #fileInput class="selectefile" type="file" accept=".jpg,.jpeg,.png" id="uploadImage1" (change)="selectFile($event)" style="visibility: hidden; display: none;">
                            <button class="btn-default btn-sm browse col-md-1" id = "bt1"  style="background-color: #049fd9" (click)="chooseFile()">
                                <div *ngIf = "!uploadChange else Change1">
                                    Browse
                                </div>
                            </button>
                            <p align = "left" class = "col-md-3" *ngIf= "fileName == null && !filesizeFlag">no file choosen</p> <p align = "left" style = "overflow: hidden" class = "col-md-3" *ngIf= "fileName != null && !filesizeFlag">{{fileName}}</p>
                            <p align = "left" class = "file-size col-md-6" *ngIf= "fileName == null && filesizeFlag">File exceeds the maximum upload limit of 5MB. Please upload a smaller size image file.</p>
                            </div>
                            </div>
                            <ng-template #Change1>
                                    Change
                            </ng-template>
                            <div style="padding-top:50px">
                            <button [disabled]="!f.valid||disable||!fileuploadFlag" class="btn-default btn-sm submit col-md-1"  style="background-color: #049fd9" 
                                (click)="uploadFile()"><span class="icon-small"></span>
                                Submit
                            </button>
                            </div>
                        </form>
                    </tab>
                    <tab heading="Set Time delay for banner">
                        <form #f2=ngForm>
                            <div class = "form-group">
                                <label style="font-size:11px" for="timeDelay">Set the time delay for banner carousel (in milliseconds)</label>
                                <p style="font-size: 11px"><i>Current value: {{getTimeDelay}} ms</i></p>
                                <input [(ngModel)]= "timeDelay" name="timeDelay" id="timeDelay" type="number" class = "form-control" required>
                            </div>
                            <div style="padding-top: 20px">
                            <button class="set btn-default btn-sm"  style="background-color: #049fd9" 
                                (click)="setBannerTimeDelay()" [disabled] = "!f2.valid" ><span class="icon-small"></span>
                                Set
                            </button>
                            </div>
                        </form>
                    </tab>
                </tabset>
            </div>
        <!-- <ul class="nav nav-tabs">
                <li class="active"><a data-toggle="tab" (click) = "toggle1()" href="#list">Existing Banners</a></li>
                <li><a data-toggle="tab" (click) = "toggle2()" href="#add">Add New Banners</a></li>
                <li><a data-toggle="tab" href="#setTimeDelay">Set Time delay for banner</a></li>
              </ul> -->
        </div>
    
        <!-- <div class = "table-size col-md-12 tab-pane in active" id = "list">
               
        </div> -->
    
        <div class="modal" id="UploadFile" role="dialog">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true" class="icon-close icon-medium"></span></button>
                  <h6 align="left" class="modal-title"><b>Edit Banner</b></h6>
                </div>
                <div class="modal-body">
                    <form #f1=ngForm>
                        <div class = "form-group">
                            <label style="font-size:11px" for="title">Title</label>
                            <input [(ngModel)]= "title" name="title" id="title" type="text" autocomplete="off" (keyup) = "blankSpaceCheck(title)" class = "form-control" required maxlength="85">
                        </div>
                        <div class = "form-group">
                            <label style="font-size:11px" for="description">Description</label>
                            <input [(ngModel)]= "description" name="description" id="description" type="text" autocomplete="off" (keyup) = "blankSpaceCheck(description)" class = "form-control" required maxlength="120">               
                        </div>
                        <div class = "form-group">
                            <label style="font-size:11px" for="link">Link</label>
                            <input [(ngModel)]= "imageUrl" name="imageUrl" id="imageUrl" type="text" autocomplete="off" (keyup) = "blankSpaceCheck(imageUrl)" class = "form-control" required>   
                        </div>
                        <div>
                        
                        <p style="font-size:11px;font-weight:700!important" align="left">Choose Image:</p>
                        <div class = "col-md-12">
                        <input #fileInput class="selectefile" type="file" accept=".jpg,.jpeg,.png" id="uploadImage2" (change)="selectFile($event)" style="visibility: hidden; display: none;">
                        <button class="btn-default btn-sm browse col-md-2"  style="background-color: #049fd9" 
                            (click)="chooseFile2()">
                            <div *ngIf = "!uploadChange else Change2">
                                Browse
                            </div>
                        </button>
                        <!-- <p align = "left" class = "col-md-4" *ngIf= "fileName == null">no file choosen</p> <p align = "left" style = "overflow: hidden" class = "col-md-7" *ngIf= "fileName != null">{{fileName}}</p> -->
                        <p align = "left" class = "col-md-4" *ngIf= "fileName == null && !filesizeFlag">no file choosen</p> <p align = "left" style = "overflow: hidden" class = "col-md-7" *ngIf= "fileName != null && !filesizeFlag">{{fileName}}</p>
                        <p align = "left" class = "file-size col-md-10" *ngIf= "fileName == null && filesizeFlag">File exceeds the maximum upload limit of 5MB. Please upload a smaller size image file.</p>
                        </div>
                        </div>
                        <ng-template #Change2>
                            Change
                        </ng-template>
                        <div style="padding-bottom: 10px;padding-top:50px">
                            <button [disabled]="!f1.valid||disable||!fileuploadFlag" class="btn-default btn-sm submit col-md-2"  style="background-color: #049fd9; align-items:initial"
                            (click)="editFile()" data-dismiss="modal" ><span class="icon-small"></span>
                            Update
                            </button>
                        </div>
                    </form>
                </div>
              </div>
            </div>
        </div>
    
        <!-- <div class = "div-size col-md-12 tab-pane" id = "add">
            
        </div>
    
        <div class = "table-size tab-pane" id = "setTimeDelay">
           
        </div> -->
    </div>