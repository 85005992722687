
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';




import { InteropGetURL } from '../interop-service/interop-gettingUrl.service'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InteropFirstService {
          private startupData: any;
          private urlValue: boolean;
          url ="";
          private res: any;

        constructor(private http: HttpClient, private getUrl: InteropGetURL ) {
                this.urlValue= getUrl.getUrlValue();
                this.url="/admin/api/users/me/";
        }



        results() {
                return this.http.get(this.url).pipe(map(res => {
                 return res;     
                }),catchError(this.handelError),);
        }

        private handelError(error: Response | any){
                location.reload();
                return observableThrowError(error.message || error);

        }

        private extractList(res: Response){
                if(res.status == 200 && this.urlValue){}
                     return (res.status === 200 ? res.json(): []);
              }

        logout() {
                var url1 = "/admin/api/users/logout";
                return this.http.get(url1).pipe(map(res=>{
                        // console.log(res);
                }))
        }

}