
import {of as observableOf,  Observable } from 'rxjs';

import {map, catchError, } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import {  InteropGetURL} from '../interop-service/interop-gettingUrl.service';



import {PlatformLocation} from '@angular/common';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class InteropAdminBannerService{
    
        private url = '/admin/api/bannerService';
        urlValue: boolean;
        response: any;

        constructor(private http: HttpClient, private geturl: InteropGetURL,private pl: PlatformLocation ) {
          geturl.setUrlValue((pl as any).location.href);
             this.urlValue =geturl.getUrlValue();
        }

        getBanners(){  
            let dummy = "dummy";
            return this.http.post(this.url+"/banners",dummy)
        //     .pipe(map(this.extractList),catchError( res => {
        //         return observableOf(this.extractList(res))  
        // }),)

        }

        private extractList(res: Response){
            if(res.status == 200 && this.urlValue){}
                 return (res.status === 200 ? res.json(): []);
          }

        addBanner(fd:FormData){
            return this.http.post(this.url+"/addbanner",fd)
            // .pipe(map( res => {
            //         this.response = res;
            // }))
        }

        deleteBanner(fd: FormData){
            return this.http.post(this.url+"/deletebanner",fd)
            // .pipe(map(res => {
            //     this.response = res;
            // }))
        }

        editBanner(fd: FormData){
            return this.http.post(this.url+"/editbanner",fd)
            // .pipe(map(res => {
            //     this.response = res;
            // }))
        }

        setBannerTimeDelay(fd: FormData){
            return this.http.post(this.url+"/settimedelay",fd)
            // .pipe(map(res => {
            //     this.response = res;
            // }))
        }

        getBannerTimeDelay(){
            let dummy = "dummy";
            return this.http.post(this.url+"/gettimedelay",dummy)
            // .pipe(
            //     map(this.extractList)
            // )
            
        //     .map(this.extractList).catch( res => {
        //         return Observable.of(this.extractList(res))  
        // })
        }

        

        
            
}


        
        
