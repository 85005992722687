<div *ngIf="availableDevices">
  <select (change)="onDeviceSelectChange($event.target.value)">
      <option value="" [selected]="!deviceCurrent">No Device</option>
      <option *ngFor="let device of availableDevices" [value]="device.deviceId" [selected]="deviceCurrent && device.deviceId === deviceCurrent.deviceId">{{ device.label }}</option>
  </select>
</div>
  
  <span >
    <p class="pro-bar" style="margin-bottom: 30px;">
      <!-- <ngb-progressbar type="primary" [value]="progress" [max]="30"></ngb-progressbar> -->
      <progressbar [value]="progress" type="primary" [max]="30" ></progressbar>
    </p>
    <ng-container *ngIf="hasPermission === undefined">
  
      <h4>Waiting for permissions.</h4>
    
      <blockquote>
        If your device does not has cameras, no permissions will be asked.
      </blockquote>
    
    </ng-container>
    
    <ng-container *ngIf="hasPermission === false">
    
      <h4>You denied the camera permission, please allow camera access from address bar to use the scanner.</h4>
    
    </ng-container>
  
    
    <ng-container *ngIf="hasDevices === false">
    
      <h4>No devices were found.</h4>
    
      <blockquote>
        I believe your device has no media devices attached to.
      </blockquote>
    
    </ng-container>
  
     
    <ng-container *ngIf="deviceInUse === true">
    
      <h4>Devices in use.</h4>
    
      <blockquote>
        Couldn't read device stream, it's probably in use by another app. Try closing the other app and reload the page.
      </blockquote>
    
    </ng-container>

    <h4 *ngIf="hasPermission && !deviceCurrent">No camera selected.</h4>

    <div [hidden]="!deviceCurrent">
      <zxing-scanner
      #scanner
      [tryHarder]="tryHarder"
      [formats]="formatsEnabled"
      (scanSuccess)="onCodeResult($event)"
      [device]="deviceCurrent"
      ></zxing-scanner>

    </div>
  </span>
  <!-- <input #Zoom type="range" /> -->
  
  
  