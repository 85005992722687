
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {SpinnerService} from '../service/spinner.service'
import { saveAs } from 'file-saver';
import { GetURL } from '../service/gettingUrl.service'
import { TealiumAnalyticsService } from './tealium-analytics.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class SingleService {
  
  urlValue: boolean;
  status: boolean;
  filterValue: any;
  constructor(private getUrl: GetURL,private http: HttpClient,  private SpinnerService: SpinnerService,
    
     private tealiumService: TealiumAnalyticsService) { 
     this.urlValue= getUrl.getUrlValue()
  
  }

  getCueImage(pid){
    return this.http.get(`/public/api/iop/networkdevice/cuecard?productId=${pid}`);
  }

  getAllCueCardPids(){
    return this.http.get('/public/api/iop/networkdevice/cueCardProductIds');
  }
 
  
  getResults(product): Observable<any> {
              if(product["searchInput"].length==0){
                product["searchInput"]=[""];
              }
              this.setsubmit(product);
              var eventAction="";
              var eventLabel="";
              var eventNumber=0;
              var urlResults="";
                    if(this.urlValue){
                              urlResults="/admin/api/networkdevice/search";
                    }
                    else{
                      urlResults="/public/api/networkdevice/search";
                    }
              this.SpinnerService.setdisplayValue(true);
                let b=JSON.stringify(product);
                // let myHeaders = new Headers();
                // myHeaders.set('Content-Type', 'application/json');    
                //       let options = new RequestOptions({ headers: myHeaders, });
                let options= {
                  headers: new HttpHeaders({'Content-Type': 'application/json'})
                };
                      return this.http.post(urlResults,b, options).pipe(
                        map(
                          (res:any)=>{
                          let body = res;//.json();
                          if(body){ this.SpinnerService.setdisplayValue(false);}
                          for(let i in product){
                            if(product[i].length>0){
                              eventAction= i+"";
                              for(let j in product[i])
                              if(product[i][j].name != undefined){
                                eventLabel=product[i][j].name;
                                // console.log('link-text(p4) :',eventLabel,' ,link-pos(p5): search ,link-id(p8): ',eventAction);
                                // this.googleAnalyticsEventsService.emitEvent("Search",eventLabel, eventAction, 10);
                                this.tealiumService.trackEvent("view","search",eventAction,eventLabel);
                              }
                            }
                          }
                          return body;
                        }),
                        catchError(this.handleError),);
      }


      
  private handleError (error: Response | any) {
            return observableThrowError(error.message || error);
  }



  setsubmit(filterValues){
    this.filterValue=filterValues;
  }

  getsubmit(){
    return this.filterValue;
  }

  getNotes(product): Observable<any> {
                    var urlResults = "";
                  if(this.urlValue){
                      urlResults="/admin/api/networkdevice/notes";}
                else{
                urlResults="/public/api/networkdevice/notes";
                } 
                    let b = JSON.stringify(product);
                    // let myHeaders = new Headers();
                    // myHeaders.set('Content-Type', 'application/json');
                    // let options = new RequestOptions({ headers: myHeaders, });
                    let options= {
                      headers: new HttpHeaders({'Content-Type': 'application/json'})
                    };
                    return this.http.post(urlResults, b, options)
                    // .pipe(
                    //   map(
                    //   (res:any) => {
                    //     let body = res.json();
                    //     return body;
                    //   }),
                    //   catchError(this.handleErrorNotes),);
    }


  private handleErrorNotes(error: Response | any) {
    console.log('error is ',error);
    this.SpinnerService.setdisplayValue(false);
            return observableThrowError(error.message || error);
  }



  saveFile(exportFile, urlResults, type): Observable<any>{
                var eventAction="";
                var eventLabel="Export";
                    var eventNumber=10;
                let b = JSON.stringify(exportFile);
              //   let myHeaders = new Headers();
              //   myHeaders.set('Content-Type', 'application/json');
              //  let options =new RequestOptions({ headers: myHeaders,responseType: ResponseContentType.Blob });
              let options= {
                headers: new HttpHeaders({'Content-Type': 'application/json'}),
                responseType: 'blob' as 'json'
              };
                return this.http.post(urlResults,b, options).pipe(
                  map(
                    (res:any) => {
                      let body = res;
                      console.log('piped response',body);
                      if(body.type == "application/json"){
                       return res.json();                      
                      }
                     if(type == 'excel'){
                        eventAction="Export Excel";               
                        // this.googleAnalyticsEventsService.emitEvent("Export",eventLabel, eventAction, 10);
                        
                        this.tealiumService.trackEvent("link","Export",eventLabel,eventAction);


                      return new Blob([res], { type: 'application/vdn-ms-excel' })
                    }
                  else if(type == 'pdf'){
                    eventAction="Export PDF";
                    // this.googleAnalyticsEventsService.emitEvent("Export",eventLabel, eventAction, 10);

                    this.tealiumService.trackEvent("link","Export",eventLabel,eventAction);
                    

                      return new Blob([res], { type: 'application/pdf' })
                    }
                    else if(type == 'csv'){
                      eventAction="Export CSV"; 
                      // this.googleAnalyticsEventsService.emitEvent("Export",eventLabel, eventAction, 10);
                      
                      this.tealiumService.trackEvent("link","Export",eventLabel,eventAction);

                      return new Blob([res], { type: 'text/csv' })
                    }
                  }),catchError(this.handleErrorNotes),);
      }

      messageExport(url: any, b: any): Observable<any>{
        let file = JSON.stringify(b);
        // let myHeaders = new Headers();
        // myHeaders.set('Content-Type', 'application/json');
        // let options =new RequestOptions({ headers: myHeaders, });
        let options= {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        return this.http.post(url,file, options).pipe(
                  map(
                    (res) => {
                      return res;
                    }))
      }


}