import {Component, HostListener, Compiler} from '@angular/core';
import {PlatformLocation} from '@angular/common';
import { Title } from "@angular/platform-browser";
import{ Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {SpinnerService} from '../../service/spinner.service';
import {MessageService} from '../../service/message.service';
import { GetURL } from '../../service/gettingUrl.service';
import {FirstService} from '../../service/first.service';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import { BannerService } from '../../service/banner.service';
import { TealiumAnalyticsService } from '../../service/tealium-analytics.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    href: string="";
  private messageDetails: any;
  private spinnerImg = "assets/images/ajax-loader1.gif";
  private spin: boolean; 
  private uploadResponse: any;
  private showSuccess: boolean;
  private showFail: boolean;
  private successScroll: any;
  private sticky: any;
  private _banners: any[];
  private timeDelay: number;
  title = 'app';
 private showwarn: any;
 private urlbanners: boolean;
 baseURL: any;
 ciscolive: boolean;
 mainListData: any;
 parent: any;
 showButton: boolean;
 change: boolean;
 interop: boolean;

  constructor(private getUrl: GetURL,private spinnerService: SpinnerService ,private message: MessageService,
     private firstservice: FirstService,private pl: PlatformLocation, private compile: Compiler,
     private banners: BannerService,
     private tealiumAnalyticsService: TealiumAnalyticsService,
      private tittleservice: Title,private route: ActivatedRoute,private router: Router,
      private cookies: CookieService) {
        this.baseURL= window.location.href;
        console.log('base url',this.baseURL);
      
        // this.route.queryParams.subscribe(param=>{
        //     console.log('param',param);
        //     console.log('query params: ',Object.keys(param).length);
        //     if(Object.keys(param).length==0 && window.location.pathname == "/"){
        //         this.ciscolive=false;
        //     }
        // })
        // if(this.baseURL.includes('ciscolive')){
        //     console.log(this.baseURL);
        //     this.ciscolive = true;
        // }
        this.ciscolive=false;
        this.interop=false;

         this.router.events.subscribe(
        (event) => {
            // console.log('event',event);
            if (event instanceof NavigationEnd) {
                console.log('navigation end');
                if(!this.ciscolive){
                    console.log('set urlvalue',(this.pl as any).location.href);
                    this.getUrl.setUrlValue((this.pl as any).location.href);   
                    this.urlbanners=getUrl.getUrlBanners();

                }

          }
        }
      );

        if(this.baseURL.includes('iop')){
            this.interop = true;
            console.log('current title:::::' + this.tittleservice.getTitle());
            this.tittleservice.setTitle('Optics Interoperability Matrix');
        }
        if(!this.ciscolive){
            localStorage.clear();
            sessionStorage.clear();
            this.cookies.deleteAll();
    //    getUrl.setUrlValue((pl as any).location.href);   
    //    this.urlbanners=getUrl.getUrlBanners();
        }
    
  }

  ngOnInit() {
    if(!this.interop){
        this.getUrl.setUrlValue((this.pl as any).location.href);
        let sol = {
            "solution": 'tmg',
        }
        this.message.getMessage(sol).subscribe(res => {
            this.messageDetails = res;
            this.spin = this.spinnerService.getdisplayValue();
        });
        this.banners.getBanners().subscribe(res => {
            this._banners = res["br"];
            this.timeDelay = res["timeDelay"];
            
             for(var banner of this._banners){
                banner.image_blob = 'data:image/jpeg;base64,'+banner.image_blob;
            }
        });  
    }
    
            
  }

  dismissCiscoLive(data){
    console.log('dismiss? ',data);
    // this.ciscolive=false;
  }


  close(e, i:any, type:any) {   
    document.getElementById(i+type).setAttribute("class", "democlass");
  }


  @HostListener('window:scroll', ['$event']) 
  stickDiv(){
      if(!this.ciscolive){
        this.successScroll= document.getElementById("success");
        if(this.successScroll != null){
        this.sticky= this.successScroll.offsetTop;
        this.divOnTop();
        }
      }
          
  }



  divOnTop(){
            if (window.pageYOffset >= this.sticky) {
                this.successScroll.classList.add("sticky");
            } else {
                this.successScroll.classList.remove("sticky");
            }
  }



  closeRes(event: string){
            if(event == "success"){
                this.showSuccess=false;
            }
            else if(event == "warn"){
                this.showwarn=false;
            }
            else{
                this.showFail=false;
            }
  }

  goBackToParent(event){
      console.log(event);
      this.parent = event;
  }

  showingButton(event){
      this.showButton = event;
  }

  setuploadResponse(res: any){
            this.uploadResponse=res;
            this.showSuccess=false;
            this.showwarn=false;
            this.showFail=false;
            if(this.uploadResponse["message"]=="Successfully Uploaded"){
                this.showSuccess=true;
            }
            else if (this.uploadResponse["type"] =="warn"){
                this.showwarn=true;
            }
            else if(this.uploadResponse["message"]){
                   this.showFail= true;
            }
        
        }


    eventEmit(title: string){
        var eventCategory = "Others";
        var eventAction = title.replace(/<[^>]+>/g, '');
        var eventLabel = "Banners";
        console.log('link Hierarchy :-  ',eventLabel,':','Banner Link',':','eventAction');
        
        this.tealiumAnalyticsService.trackEvent("link","Banner Link",eventLabel,eventAction);
        // this.googleAnalyticsEventsService.emitEvent(eventCategory,eventAction, eventLabel,15);
    }
}