
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {  GetURL} from '../service/gettingUrl.service';



import {PlatformLocation} from '@angular/common';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class MessageService {
        
        private url : string;
        urlValue: boolean;

        constructor(private http: HttpClient, private geturl: GetURL,private pl: PlatformLocation ) {
          geturl.setUrlValue((pl as any).location.href);
             this.urlValue =geturl.getUrlValue();
        }


        getMessage(solution) {
          if(this.urlValue){
            this.url='/admin/api/networkdevice/message';
          }
          else{
          this.url = '/public/api/networkdevice/message';
        }
                return this.http.post(this.url, solution);
              //   .pipe(map(this.extractList),catchError( res => {
              //   return observableOf(this.extractList(res))
              
              // }),)
            
        }


        private extractList(res: Response){
          if(res.status == 200 && this.urlValue){}
               return (res.status === 200 ? res.json(): []);
        }

}