import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



import { InteropGetURL } from '../interop-service/interop-gettingUrl.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class InteropSuggesionService {
      private states: Array<string>;
      private url : string;
      urlValue: boolean; 

      constructor(private http: HttpClient, private getUrl: InteropGetURL ) {
                this.urlValue= getUrl.getUrlValue();
                if(this.urlValue){
                  this.url="/admin/api/iop/networkdevice/autosuggest";
                  }
                  else{
                    this.url="/public/api/iop/networkdevice/autosuggest";
                  }
        }


        
      getResult(searchValue:string): Observable<any> {
        var suggestionInput = {
          "suggestionText":searchValue
        };
                return this.http.post(this.url,suggestionInput)
                // .pipe(map(this.extractList));
                
                // .map(this.extractList).catch( res => {
                //   return Observable.of(this.extractList(res))
                // })
      }



      private extractList(res: Response){
              return (res.status === 200 ? res.json(): []);
      }
}