<div class="row">
    <div class="alert alert--danger" id="delete" *ngIf="showTimeout">
        <div class="alert__icon icon-time"></div>
        <div class="alert__message">Scanner timed out</div>
        <div class="alert__close icon-close" (click)="closeRes('timeout')"></div>
    </div>

    <div class="alert alert--danger" id="delete" *ngIf="showScanError">
        <div class="alert__icon icon-error"></div>
        <div class="alert__message"> {{errMsg}}</div>
        <div class="alert__close icon-close" (click)="closeRes('error')"></div>
    </div>
</div>
<div class="row">


    <div class="col-md-2 filters filters_safari" *ngIf="show">
        <app-filters [flag]="clearFlag" [jData]="searchResults" [searchInputList]="submit" (ResultsChanged)="SearchResultsHandler($event)" (InputChanged)="InputHandler($event)" (Changed)="newSearchHandler($event)"></app-filters>
    </div>
  
    


    <div class="col-md-10 search">
        <h6 *ngIf="!show">Begin your Search (Type in window)</h6>
        <h6 *ngIf="show">Search (Shows up to 3 suggestions)</h6>
        <form>
            <div class="form-group">
                <div class="form-group__text">
                    <input type="search" name="selectedOptions"  #single [(ngModel)]="selectedOptions" autocomplete="off" id="singleSearch" 
                    (keyup)="autoSuggest($event)" 
                    placeholder="Search for Product Family, Product ID, Data Rate, Reach, Cable Type, Form Factor;  Example: QSFP100, QSFP-100G-LR4, 100 Gbps, Duplex Fiber, QSFP28">
                    <button type="button" class="link" (click)="fetchResults(single.value)">
                        <span class="icon-search" ></span>
                    </button>
                    <div *ngIf="showSuggession" class="suggests" id="suggestion">
                        <ul *ngFor="let obj of suggestionList | suggest; "  class="suggestionList {{ j }}">
                            <li #myElement class="suggestitem " id = "{{obj1.name}}{{obj}}" *ngFor="let obj1 of suggestionList[obj];"
                                (click)="navigate(obj,obj1);clickedInside($event)"
                                [class.active]= "obj1.name+obj == suggestionid">{{obj1.name}}
                                <span class="type"> in {{obj | splitCamelCase }} </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
        <div style="margin-bottom: 20px;">
            <button type="button" style="background-color: rgb(6 109 74);font-size: small;" class="btn" data-toggle="modal" data-target="#QrLink">
                <div style="float: left;margin: 10px 10px 0px -10px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">
                    <path d="M2 2h2v2H2V2Z"/>
                    <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"/>
                    <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"/>
                    <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"/>
                    <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"/>
                  </svg>
                  </div>
                  <div>
                      Scan Optics in the Field<br/>
                      Get Mobile Application
                  </div>
                  
            </button>

            <span style="float:right;">
                <button style="background-color: #fbab18;color: black;" type="button"  (click)="enableScanner()"  class="btn" data-toggle="modal" data-target="#WebScanner">
                    <span class="icon-camera icon-small">&nbsp;</span>Scanner Mode
                </button> &nbsp; <div class="icon-info" tooltip="Minimum requirement for camera is 4K." placement="right"></div>
            </span>
    
        </div>
        <app-table *ngIf="show" [flag]="clearFlag" [dropdown]="currentValue" [tableData]="searchResults" [searchInputList]="submit" (show)="sendResponse($event)" (ResultsChanged)="SearchResultsHandler($event)" (InputChanged)="InputHandler($event)"></app-table>
    </div>

    <div class="modal" id="WebScanner" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div style="display: flex;justify-content: end;">
                <a style="color:#00bceb;">
                    <span *ngIf="!help" (click)="Help()">Help</span>
                    <span *ngIf="help" (click)="Help()">Scanner Mode</span>
                </a>
            </div>
            <div class="modal-header">
                <div></div>
                <h6 class="modal-title"><b>Cisco Optics Scanner</b></h6>
                <button #closeModal type="button" (click)="closeScanner()" style="margin: 0px;padding: 0px;" class="close" data-dismiss="modal"><span aria-hidden="true" class="icon-close icon-medium"></span></button>
            </div>
            <div class="modal-body">

                <div class="wrapper">
                    <section class="content">
                        <h5>Scan ECI Barcode</h5><br>
                        <span *ngIf="scannerOnline">
                            <!-- show scanner here -->
                            <app-web-scanner  [scannerState]="scannerOnline" (closeParent)="closeScannerApp($event)"></app-web-scanner>

                        </span>
                    </section>
                    <section *ngIf="help" style="height: 400px;"></section>
                    <div *ngIf="help" class="overlay help">
                        <br>
                        <h5 style="font-weight: 400;">{{helpDetails.title}}</h5>
                        <span class="description" [innerHTML]="helpDetails.description | safeHtml"></span> 
                        <carousel>
                        <slide *ngFor="let image of helpDetails.images">
                            <div class="wrapper">
                            <img [src]="getHelpImage(image.image_blob)">
                            </div>
                        </slide>
                        </carousel>
                    </div>
    
                </div>
                
            </div>
          </div>
        </div>
    </div>

    <div class="modal" id="QrLink" role="dialog">
        <div class="modal-dialog">
        
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
                <div></div>
                <!-- <div></div> -->
                <h4><b>Scan to get Cisco Optics Mobile Scanner</b></h4>
                    <button type="button" style="margin: 0px;padding: 0px;" class="close" data-dismiss="modal"><span aria-hidden="true" class="icon-close icon-medium"></span></button>
               
            </div>
            <div class="modal-body">
    
                <div class="row">
                    <div class="col">
                        <img src="assets/images/qr-code-apple-icon.png" alt="Apple store qr link">
                        <a target="_blank" href="https://apps.apple.com/us/app/cisco-optics-scanner/id1623486956">iOS App Store</a>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col">
                        <img src="assets/images/qr-code-google-icon.png" alt="Play store qr link">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cisco.coscanner">Google Play Store</a>
    
                    </div>
                </div>
                
               
            </div>
          </div>
          
        </div>
      </div>

</div>

