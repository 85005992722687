import {Component, HostListener, Compiler} from '@angular/core';
import {PlatformLocation} from '@angular/common';
import {InteropSpinnerService} from '../../interop-service/interop-spinner.service';
import {InteropMessageService} from '../../interop-service/interop-message.service';
import { InteropGetURL } from '../../interop-service/interop-gettingUrl.service';
import {InteropFirstService} from '../../interop-service/interop-first.service';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import { InteropBannerService } from "../../interop-service/interop-banner.service";

import { InteropTealiumAnalyticsService } from '../../interop-service/interop-tealium-analytics.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'interopapp-root',
  templateUrl: './interop-app.component.html',
  styleUrls: ['./interop-app.component.scss']
})
export class InteropAppComponent {
    href: string="";
  private messageDetails: any;
  private spinnerImg = "assets/images/ajax-loader1.gif";
  private spin: boolean; 
  private uploadResponse: any;
  private showSuccess: boolean;
  private showFail: boolean;
  private successScroll: any;
  private sticky: any;
  private _banners: any[];
  private timeDelay: number;
  title = 'app';
 private showwarn: any;
 public urlbanners: boolean;
 baseURL: any;
 ciscolive: boolean;
 mainListData: any;
 parent: any;
 showButton: boolean;
 change: boolean;

  constructor(private getUrl: InteropGetURL,public spinnerService: InteropSpinnerService ,private message: InteropMessageService,
     private firstservice: InteropFirstService,private pl: PlatformLocation, private compile: Compiler,private banners: InteropBannerService,
    private tealiumAnalyticsService: InteropTealiumAnalyticsService, private router: Router
     ) {
        this.baseURL= window.location.href;

        this.router.events.subscribe(
            (event) => {
                // console.log('event',event);
                if (event instanceof NavigationEnd) {
                    console.log('navigation end');
                    if(!this.ciscolive){
                        console.log('set urlvalue',(this.pl as any).location.href);
                        getUrl.setUrlValue((pl as any).location.href);   
                        this.urlbanners=getUrl.getUrlBanners();
    
                    }
    
              }
            }
          );

            localStorage.clear();
            sessionStorage.clear();
            // cookies.removeAll();
    //    getUrl.setUrlValue((pl as any).location.href);   
    //    this.urlbanners=getUrl.getUrlBanners();
    
    
  }

  ngOnInit() {

    
        this.getUrl.setUrlValue((this.pl as any).location.href);
        var sol = {
            "solution":'iop',
        }
        this.message.getMessage(sol).subscribe(res => {
            this.messageDetails = res;
            this.spin = this.spinnerService.getdisplayValue();
        });
        this.banners.getBanners().subscribe(res => {
            this._banners = res["br"];
            this.timeDelay = res["timeDelay"];
            
             for(var banner of this._banners){
                banner.image_blob = 'data:image/jpeg;base64,'+banner.image_blob;
            }
            console.log(this._banners)
        }); 
    
            
  }



  close(e, i:any, type:any) {   
    document.getElementById(i+type).setAttribute("class", "democlass");
  }


  @HostListener('window:scroll', ['$event']) 
  stickDiv(){
      if(!this.ciscolive){
        this.successScroll= document.getElementById("success");
        if(this.successScroll != null){
        this.sticky= this.successScroll.offsetTop;
        this.divOnTop();
        }
      }
          
  }



  divOnTop(){
            if (window.pageYOffset >= this.sticky) {
                this.successScroll.classList.add("sticky");
            } else {
                this.successScroll.classList.remove("sticky");
            }
  }



  closeRes(event: string){
            if(event == "success"){
                this.showSuccess=false;
            }
            else if(event == "warn"){
                this.showwarn=false;
            }
            else{
                this.showFail=false;
            }
  }

  goBackToParent(event){
      console.log(event);
      this.parent = event;
  }

  showingButton(event){
      this.showButton = event;
  }

  setuploadResponse(res: any){
            this.uploadResponse=res;
            this.showSuccess=false;
            this.showwarn=false;
            this.showFail=false;
            console.log(this.uploadResponse)
            if(this.uploadResponse["type"] === "info"){
                this.showSuccess=true;
                this.showwarn = false;
                this.showFail = false;
            }
            else if (this.uploadResponse["type"] === "warn"){
                this.showwarn=true;
                this.showFail = false;
                this.showSuccess = false;
            }
            else if(this.uploadResponse["type"] === "error"){
                   this.showFail= true;
                   this.showSuccess=false;
                this.showwarn = false;
            }
        
        }


    eventEmit(title: string){
        var eventCategory = "Others";
        var eventAction = title.replace(/<[^>]+>/g, '');
        var eventLabel = "Banners";
        console.log('link Hierarchy :-  ',eventLabel,':','Banner Link',':','eventAction');
        // this.tealiumAnalyticsService.trackLink(eventLabel,"Banner Link-interop",eventAction);
        this.tealiumAnalyticsService.trackEvent("link","Banner Link",eventLabel,eventAction);
        // this.tealiumAnalyticsService.trackLink(eventLabel,"banner",eventAction);
    }
}