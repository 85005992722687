import { Injectable } from '@angular/core';

@Injectable()
export class InteropGetURL {
    private urlValue: boolean;
    private urlBanners: boolean;
    private scanner: boolean;


      constructor() { 
        this.urlValue = false;
       }


       public setUrlValue(url: string) {
            if(url.includes("banners")&&url.includes("admin")){
                this.urlBanners=true;
            }
            else{
                this.urlBanners=false;
            }
            if(url.includes("admin")){
                this.urlValue= true;
            }
            else{
                this.urlValue =false;
            }
            // if(url.includes('scanner')){
            //     this.scanner=true;
            // }else{
            //     this.scanner=false;
            // }
        }

        public getUrlBanners(){
            return this.urlBanners;
        }

        public getUrlValue(): boolean {
            return this.urlValue;
        }

        // public getScannerValue(){
        //     return this.scanner;
        // }
}