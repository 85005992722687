import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { InteropDataService } from '../interop-service/interop-shared.service';


@Injectable()
export class InteropNavigateService {
        queryParamsFilters:any;
        fullSearch: boolean;
       
        constructor(private router: Router,private data:InteropDataService) {
             this.queryParamsFilters={
                        "cableType": [],
                        "dataRate": [],
                        "formFactor": [],
                        "reach": [],
                        "searchInput": [],
                        "osType": [],
                        "transceiverProductFamily": [],
                        "transceiverProductID": [],
                        "networkDeviceProductFamily":[],
                        "networkDeviceProductID":[],
                        "breakoutPatchcordRequired":[],
                        "media": [],
                        "connectorType": [],
                        "caseTemperature":[]
             };
             this.fullSearch = false;
        }

        autoFill(routeRes:any,searchInput:any){
            for(let filtername in routeRes){
                if(filtername == "ostype"){
                    this.queryParamsFilters["osType"] = routeRes[filtername];
                }
                else{
                this.queryParamsFilters[filtername] = routeRes[filtername];
                }
                this.queryParamsFilters["searchInput"] = searchInput;
            }
        }

        editFilters(value:any,filtername:string,isNavigate: boolean){
            if(filtername=="searchInput"){
                this.queryParamsFilters[filtername] = [];
                if(value[0]!=""){
                    this.queryParamsFilters[filtername]= value;
                }
                let isEmpty = true;
                for(let filtername in this.queryParamsFilters){
                    if(this.queryParamsFilters[filtername].length>0)
                    isEmpty = false;
                }
                if(isEmpty)
                    this.fullSearch = true;
            }
            else
            {
              
                if(filtername == "ostype"){
                    filtername= "osType";
                } 
            value.filterChecked = true;
            value.filtername = filtername;
            let found;
            if(filtername == "caseTemperature"){
                found = this.queryParamsFilters[filtername].find(obj=> obj.name == value.name);
            }else{
                found=this.queryParamsFilters[filtername].find(obj=>obj.id==value.id);
            }
            if(this.queryParamsFilters[filtername].length>0){
            if(found==undefined){
                this.queryParamsFilters[filtername].push(value);
            }
            else{
                for(var i=0; i<this.queryParamsFilters[filtername].length;i++){
                    if(filtername == 'caseTemperature'){
                        if(this.queryParamsFilters[filtername][i].name == value.name && !isNavigate){
                            this.queryParamsFilters[filtername].splice(i,1);
                            for(var i=0;i<this.data.data.length;i++){
                                if(this.data.data[i]["id"]==value.id && this.data.data[i]["name"]==value.name){
                                    this.data.data.splice(i,1);
                                    this.data.addFilter(value,filtername);
                                }
                            }
                            break;
                        }

                    }else{
                        if(this.queryParamsFilters[filtername][i].id==value.id&&!isNavigate){
                            this.queryParamsFilters[filtername].splice(i,1);
                            for(var i=0;i<this.data.data.length;i++){
                                if(this.data.data[i]["id"]==value.id && this.data.data[i]["name"]==value.name){
                                    this.data.data.splice(i,1);
                                    this.data.addFilter(value,filtername);
                                }
                            }
                            break;
                        }
                    }

                }
                let isEmpty = true;
                for(let filtername in this.queryParamsFilters){
                    if(filtername=="searchInput")
                    continue;
                    else {
                    if(this.queryParamsFilters[filtername].length>0)
                    isEmpty = false;
                    }
                }
                if(isEmpty)
                    this.fullSearch = true;
            }
            }
            else {
                this.queryParamsFilters[filtername].push(value);
            }
            }

           
        }

        IDArray(filtername){
            var idarray = [];
            if(filtername=="searchInput"){
                for(let value of this.queryParamsFilters[filtername]){
                    idarray.push(value);
                } 
            }
            else if(filtername== "caseTemperature"){
                for(let value of this.queryParamsFilters[filtername]){
                    idarray.push(value.name);
                }
            }else{
                for(let value of this.queryParamsFilters[filtername]){
                    idarray.push(value.id);
                }
            }

            return idarray;
        }

        navigateToURL(){
            var queryParams = {};
            for(let filtername in this.queryParamsFilters){
                if(filtername=="cableType"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["ct"] = this.IDArray(filtername);
                }
                if(filtername=="dataRate"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["dr"] = this.IDArray(filtername);
                }
                if(filtername=="formFactor"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["ff"] = this.IDArray(filtername);
                }
                if(filtername=="reach"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["rh"] = this.IDArray(filtername);
                }
                if(filtername=="searchInput"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["si"] = this.IDArray(filtername);
                }
                if(filtername=="osType"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["os"] = this.IDArray(filtername);
                }
                if(filtername=="transceiverProductFamily"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["tpf"] = this.IDArray(filtername);
                }

                if(filtername=="transceiverProductID"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["tpid"] = this.IDArray(filtername);
                }
                if(filtername=="networkDeviceProductFamily"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["npf"] = this.IDArray(filtername);
                }
                if(filtername=="networkDeviceProductID"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["npid"] = this.IDArray(filtername);
                }
                if(filtername=="breakoutPatchcordRequired"&&this.queryParamsFilters[filtername].length>0){
                    queryParams["brk"] = this.IDArray(filtername);
                }
                if(filtername=="media" && this.queryParamsFilters[filtername].length > 0){
                    queryParams["me"] = this.IDArray(filtername);
                }
                if(filtername=="connectorType" && this.queryParamsFilters[filtername].length > 0){
                    queryParams["cct"] = this.IDArray(filtername);
                }
                if(filtername=="caseTemperature" && this.queryParamsFilters[filtername].length > 0){
                    queryParams["cst"] = this.IDArray(filtername);
                }

            }
            this.router.navigate([],{queryParams:queryParams});
        }

        emptyParams(){
            this.queryParamsFilters["cableType"] = [];
            this.queryParamsFilters["dataRate"] = [];
            this.queryParamsFilters["formFactor"] = [];
            this.queryParamsFilters["reach"] = [];
            this.queryParamsFilters["osType"] = [];
            this.queryParamsFilters["transceiverProductFamily"] = [];
            this.queryParamsFilters["transceiverProductID"] = [];
            this.queryParamsFilters["networkDeviceProductFamily"] = [];
            this.queryParamsFilters["networkDeviceProductID"] = [];
            this.queryParamsFilters["breakoutPatchcordRequired"]=[];
            this.queryParamsFilters["media"]=[];
            this.queryParamsFilters["connectorType"]=[];
            this.queryParamsFilters["caseTemperature"]=[];
            this.fullSearch = true;
            this.data.data = [];
        }

}