import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ScannerService {

  constructor(private http:HttpClient) { }


  getHelpTips(baseUrl){
    let url = `${baseUrl}/api/dpi/barcode/help/list`;
     return this.http.get(url);
  }

  getTranceiverDetails(ecid){
    return this.http.post('/public/api/networkdevice/barcode/searchTransceiver',ecid)
  }

  

}
