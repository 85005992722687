

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, enableProdMode, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { APP_BASE_HREF, Location } from '@angular/common';

// import { InteropAppComponent } from '../interop/interop-component/interop-app/interop-app.component';
// import { InteropHomeComponent } from '../interop/interop-component/interop-home/interop-home.component';
// import{ InteropHeaderComponent } from '../interop/interop-component/interop-header/interop-header.component';
// import { InteropFooterComponent } from '../interop/interop-component/interop-footer/interop-footer.component';
// import{ InteropSuggesionService } from "../interop/interop-service/interop-autoSuggestion.service";
// import { InteropSingleService } from '../interop/interop-service/interop-singleSearch.service';
// import{InteropFilterPipe} from '../interop/interop-component/interop-filters/interop-filter.pipe';
// import{ InteropFilterpipePipe} from '../interop/interop-component/interop-filters/interop-filterpipe.pipe';
// import{ InteropFirstService } from '../interop/interop-service/interop-first.service';
// import { InteropSuggestPipe } from '../interop/interop-component/interop-home/interop-suggestion-pipe.pipe';
// import { InteropNospacePipe } from '../interop/interop-component/interop-filters/interop-nospace.pipe';
// import { InteropFiltersComponent } from '../interop/interop-component/interop-filters/interop-filters.component';
// import{InteropCapitalizePipe} from '../interop/interop-component/interop-home/interop-capitalize.filter';
// import { InteropTableComponent } from '../interop/interop-component/interop-table/interop-table.component';

// import {InteropSpinnerService} from '../interop/interop-service/interop-spinner.service'
// import { InteropDataService } from '../interop/interop-service/interop-shared.service';
// import { InteropMessageService } from '../interop/interop-service/interop-message.service';
// import{ InteropGetURL } from '../interop/interop-service/interop-gettingUrl.service';
// import { InteropArraySortPipe} from '../interop/interop-component/interop-filters/interop-filtersort.pipe';
// import {InteropSplitCamelCase} from '../interop/interop-component/interop-home/interop-splitcamelcase.pipe';
// import { InteropBannerService } from '../interop/interop-service/interop-banner.service';
// import { InteropAdminBannerService } from '../interop/interop-service/interop-adminbanner.service';
// import { InteropAdminBannerComponent } from '../interop/interop-component/interop-adminbanner/interop-adminbanner.component';
// import { InteropRouteInformationService } from '../interop/interop-service/interop-routeInformation.service';
// import { InteropEmptyComponent } from '../interop/interop-component/interop-empty/interop-empty.component';
// import { InteropLogoutComponent } from '../interop/interop-component/interop-logout/interop-logout.component';
// import { InteropNavigateService } from '../interop/interop-service/interop-navigate.service';


import { AppComponent } from '../component/app/app.component';
import { HomeComponent } from '../component/home/home.component';
import { routing, appRoutingProviders } from '../route/app.route';
// import{ HeaderComponent } from '../component/header/header.component';
import { FooterComponent } from './../component/footer/footer.component';
import{ SuggesionService } from "./../service/autoSuggestion.service";
import { SingleService } from './../service/singleSearch.service';
import{ environment } from '../../environments/environment';

import{FilterPipe} from '../component/filters/filter.pipe';
import{ FilterpipePipe} from '../component/filters/filterpipe.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import{ FirstService } from '../service/first.service';
import { SuggestPipe } from '../component/home/suggestion-pipe.pipe';
import { NospacePipe } from '../component/filters/nospace.pipe';
import { FiltersComponent } from '../component/filters/filters.component';
import{CapitalizePipe} from '../component/home/capitalize.filter';
import { TableComponent } from '../component/table/table.component';

import {HttpClientModule} from '@angular/common/http';
// import {NgxPaginationModule} from 'ngx-pagination';
import {SpinnerService} from '../service/spinner.service'
import { DataService } from './../service/shared.service';
import { MessageService } from '../service/message.service';
import{ GetURL } from '../service/gettingUrl.service'
// import { CookieService } from 'angular2-cookie/services/cookies.service';
// import { Ng2OrderModule } from 'ng2-order-pipe';
import { ArraySortPipe} from '../component/filters/filtersort.pipe';
import {SplitCamelCase} from '../component/home/splitcamelcase.pipe'
import { BannerService } from '../service/banner.service';
import { AdminBannerService } from '../service/adminbanner.service';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AdminBannerComponent } from '../component/adminbanner/adminbanner.component';
import { RouterModule, Routes} from '@angular/router';
import { RouteInformationService } from '../service/routeInformation.service';
import { EmptyComponent } from '../component/empty/empty.component';
import { LogoutComponent } from '../component/logout/logout.component';
import { NavigateService } from '../service/navigate.service';

import { RemoveHTMLPipe } from "../cisco-live/cisco-live-pipes/cisco-live-removeHTMLtag.pipe";
import { InteropAppModule } from "../interop/interop-module/interop-app.module";
import { RecursiveSearchPipe } from "../interop/interop-component/interop-table/interop-tablejsondata.pipe";
import { TealiumAnalyticsService } from '../service/tealium-analytics.service';
import { InteropTealiumAnalyticsService } from '../interop/interop-service/interop-tealium-analytics.service';
import { LandingPageComponent } from '../component/landing-page/landing-page.component';
import { SharedModule } from '../shared/shared.module';
import { CiscoLiveHeaderComponent } from '../cisco-live/cisco-live-component/cisco-live-header/cisco-live-header.component';
import { CiscoLiveHomeComponent } from '../cisco-live/cisco-live-component/cisco-live-home/cisco-live-home.component';
import { CiscoLiveListsComponent } from '../cisco-live/cisco-live-component/cisco-live-lists/cisco-live-lists.component';
// if (environment.production) {
//   enableProdMode();
// }


@NgModule({
  declarations: [
    LandingPageComponent,
    CiscoLiveHeaderComponent,
    CiscoLiveHomeComponent,
    CiscoLiveListsComponent,
    AppComponent,FilterpipePipe,HomeComponent,FiltersComponent,TableComponent,FooterComponent,
    SuggestPipe,NospacePipe,CapitalizePipe, RemoveHTMLPipe, SplitCamelCase ,ArraySortPipe,FilterPipe,
    AdminBannerComponent,RecursiveSearchPipe
  ],
  imports: [
    SharedModule,
    BrowserModule,FormsModule, HttpClientModule, 
    RouterModule.forRoot([
      // {
      //   path:"",
      //   component:EmptyComponent
      // },
      
      // {
      //   path: "tmg",
      //   component:  EmptyComponent
      // },
      // {
      //   path:"loggingout",
      //   component:LogoutComponent
      // },
      // {
      //   path:"admin/banners",
      //   component:EmptyComponent
      // },
      // {
      //   path:"admin",
      //   component:EmptyComponent
      // },
      {
        path: "",
        loadChildren: ()=> import('../tmg/tmg.module').then(m => m.TmgModule)
      },
      {
        path: "iop",
        loadChildren: ()=> import('../interop/interop-module/interop-app.module').then(m=> m.InteropAppModule)
      }
     
      // {
      //   path:"iop",
      //   component: EmptyComponent
      // },
      // {
      //   path:"iop/loggingout",
      //   component:InteropLogoutComponent
      // },
      // {
      //   path:"admin/iop/banners",
      //   component:InteropEmptyComponent
      // },
      // {
      //   path:"admin/iop",
      //   component:InteropEmptyComponent
      // },
      
     
    ])

  ],
  providers: [appRoutingProviders,SuggesionService,SingleService,DataService,SpinnerService,MessageService,GetURL,
    FirstService,BannerService,AdminBannerService,Title,
     RouteInformationService, NavigateService,
     TealiumAnalyticsService,InteropTealiumAnalyticsService,
    { provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }