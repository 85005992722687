<router-outlet></router-outlet>
<div  class="main-container">
                <div class="app-container">
                        <!-- <div> <interopapp-header></interopapp-header></div>. -->
                            <br><br>
                            <div class="loading-spinner flex-center"  *ngIf="spinnerService.getdisplayValue()">
                                    <div class="wrapper">
                                        <div class="wheel"></div>
                                    </div>
                            </div>

                            <div *ngIf = urlbanners>
                                <interopapp-adminbanner></interopapp-adminbanner>
                            </div>
                            <div *ngIf = !urlbanners>
                                <div class="head">
                                        <div class="row">
                                            <div class="message">
                                                <h3>Cisco Optics-to-Optics Interoperability Matrix</h3>
                                                <div class="col-md-12 col-lg-12" style="padding-left:0px;">
                                                    <b><font color="red">Disclaimer: </font></b>Cisco makes the data in this tool available for informational purposes. Cisco does not represent, warrant, or guarantee that it is complete, accurate, or up to date. This information is subject to change without notice.
                                                 </div>
                                                <div class="alert"  style="border:none" id= "{{i}}info"  *ngFor="let info of messageDetails?.info, let i=index">
                                                    <div class="alert__icon icon-info-circle" style="color: #6abf4b;"></div>
                                                    <div class="alert__message" [innerHTML]="info"> </div>
                                                    <span class="alert__close icon-close" (click)="close($event,i,'info')"></span>
                                                </div>

                                                <div class="alert alert--warning" id= "{{i}}warn" *ngFor="let warn of messageDetails?.warn; let i=index">
                                                    <div class="alert__icon icon-exclamation-triangle"></div>
                                                    <div class="alert__message" [innerHTML]="warn"> </div>
                                                    <span class="alert__close icon-close" (click)="close($event,i,'warn')"></span>
                                            
                                                </div>
                                                <div class="alert alert--danger" id= "{{i}}danger" *ngFor="let danger of messageDetails?.error; let i=index">
                                                    <div class="alert__icon icon-error"></div>
                                                    <div class="alert__message" [innerHTML]="danger"> </div>
                                                    <span class="alert__close icon-close" (click)="close($event,i,'danger')"></span>
                                                </div>
                                            </div>
                                            <div class = " col-md-5">
                                                <div class = "col-md-12 col-md-offset-0" style="padding: 0px">
                                                <carousel class="text-center" [interval]="timeDelay">
                                                <slide *ngFor = "let banner of _banners">
                                                    <a (click)="eventEmit(banner.title)" target="_blank" href = "{{banner.imageUrl}}">
                                                    <img src="{{banner.image_blob}}" class="img-main-size">
                                                    <div class = "carousel-caption d-none d-md-block">
                                                        <p align="left" style="font-size: 0.76vw; line-height: 1.5; overflow: hidden;height: 48%;margin:2% 0% 0% 0%"><span [innerHTML] = "banner.title"></span></p>
                                                        <p align="left" style="font-size: 0.76vw; line-height: 1.5;height: 36%;margin:0% 0% 0% 0%"><span [innerHTML] = "banner.description"></span></p>
                                                    </div>
                                                    </a>
                                                </slide> 
                                                </carousel>
                                                </div>
                                            </div>  
                                        </div>
                                        <div class="alert alert--success" id="success" (scroll)="stickDiv($event)" *ngIf="uploadResponse != undefined && showSuccess">
                                            <div class="alert__icon icon-check"></div>
                                            <div class="alert__message" >{{uploadResponse.message}}</div>
                                            <div class="alert__close icon-close" (click)="closeRes('success')"></div>
                                        </div>
                                        <div class="alert alert--danger" id="success" (scroll)="stickDiv($event)" *ngIf="uploadResponse != undefined && showFail" >
                                            <div class="alert__icon icon-error">{{uploadResponse.message}}</div>
                                            <div class="alert__message"></div>
                                            <div class="alert__close icon-close" (click)="closeRes('fail')"></div>
                                        </div>
                                        <div class="alert alert--warning" id="success" (scroll)="stickDiv($event)" *ngIf="uploadResponse != undefined && showwarn">
                                            <div class="alert__icon icon-exclamation-triangle"></div>
                                            <div class="alert__message">{{uploadResponse.message}}</div>
                                            <div class="alert__close icon-close" (click)="closeRes('warn')"></div>
                                        </div>
                                </div>

                                <div class="box-sizing">
                                    <interopapp-home (uploadResponse)="setuploadResponse($event)"></interopapp-home>
                                </div>
                            </div>
                            <!-- <interopapp-footer></interopapp-footer> -->
                    

                </div>

    </div>


    
    