import { Injectable } from '@angular/core';


declare var ctm: any;
declare var trackEvent:any;


@Injectable()
export class TealiumAnalyticsService {

  constructor() { }

  public trackEvent(eventType,postion,uniqueId,text){
    try{
      trackEvent.event(eventType,{
        "lpos": postion,
        "lid": uniqueId,
        "linktext": text
      })
    }catch(err){
      console.log('Tealium Analytics Error');
    }
  }

  public trackLink(position,id,text){
    console.log(`lpos${position}: lid:${id}: linktext:${text}`);
    try{
      ctm.track("link",{
        "lpos": position,
        "lid": id,
        "linktext": text
      })
    }catch(err){
      console.log('Tealium Analytics Link event Error');
    }
  }

  public trackImpression(position,id,text){
    try{
      ctm.track("impression",{
        "lpos": position,
        "lid": id,
        "linktext": text
      })
    }catch(err){
      console.log('Tealium Analytics Error: impression event');
    }
  }

  public trackView(position,id,text){
    try{
      ctm.track("view",{
        "lpos": position,
        "lid": id,
        "linktext": text
      })
    }catch(err){
      console.log('Tealium Analytics Error: view event');
    }
  }

}
