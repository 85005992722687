import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
// import { BarcodeFormat} from '@zxing/library';
import { ScannerService } from '../../service/scanner.service';
import { Subscription } from 'rxjs';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat, Result } from '@zxing/library';


@Component({
  selector: 'app-web-scanner',
  templateUrl: './web-scanner.component.html',
  styleUrls: ['./web-scanner.component.scss']
})
export class WebScannerComponent implements OnInit {


  
  cameraId;
  stream;
  qrResultString;

  formatsEnabled :BarcodeFormat[] = [
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
  ];

  enabled:boolean=false;
  availableDevices: MediaDeviceInfo[];
  deviceCurrent: MediaDeviceInfo=null;
  deviceSelected: string='';
  hasDevices: boolean;
  hasPermission: boolean;
  tryHarder = true;
  timer;
  interval;
  progress=0;
  deviceInUse:boolean;
  hasCamera:boolean;
  autostart=false;
  subscriptionCameras:Subscription;
  initial=true;
  @ViewChild('scanner', {static: true}) scanner:ZXingScannerComponent;
  
  constructor(
    private scannerService: ScannerService,
    // private alertService: AlertService
  ) { }

  @Input() scannerState;
  @Output() closeParent= new EventEmitter();

  ngOnChanges(changes: SimpleChange){
    console.log('state changed ',changes['scannerState']);
    console.log('available devices: ',this.availableDevices);
      console.log('current device: ',this.deviceCurrent);
      console.log('device selected ',this.deviceSelected);
    // console.log('changes current value',changes.currentValue);
    // if(changes?.scannerState)
    if(changes['scannerState'] && changes['scannerState'].currentValue){
      console.log('state changed to',changes['scannerState'].currentValue);
      this.enabled=true;
     
    
    }else{
      this.enabled=false;
      this.progress=0;
    }
  }

  ngAfterViewInit(){
    console.log('scanner is ',this.scanner);
  }


  scannerInit() {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      console.log('got devices',devices);
      console.log('device current',this.deviceCurrent);
      this.hasDevices=true;
      this.availableDevices = devices;
      // this.deviceCurrent = null;
      this.initial=false;

     // this.compareWith = this.compareWithFn;
      // this.onDeviceSelectChange(devices[devices.length - 1].deviceId);
    });    
   
    this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
    this.scanner.scanComplete.subscribe((result: Result) => this.qrResultString = result);
    this.scanner.permissionResponse.subscribe((perm: boolean) => this.hasPermission = perm);
  }

  
  ngOnDestroy(): void {
    // this.clearAllTimeouts();
    if(this.timer){
      clearInterval(this.timer);
    }
    if(this.interval){
      clearInterval(this.interval);
    }
    this.deviceCurrent=null;
    this.deviceSelected='';
  }



  ngOnInit(): void {
    // let res=await this.scanner.askForPermission();

    this.scannerInit();
    this.initial=true;
    this.Showprogress();
    this.setTimer();
  }

  

  onDeviceSelectChange(selected: string) {
    console.log('selected value',selected);
    this.deviceSelected= selected;
    console.log('devie selected: ',this.deviceSelected);
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || null;
    console.log('current is ',this.deviceCurrent);
    console.log('timer ',this.timer);
    if(this.timer){
      clearInterval(this.timer);
    }
    console.log('progress',this.interval);
    if(this.interval){
      clearInterval(this.interval);
    }
    
    this.Showprogress();
    this.setTimer();
  }

  onDeviceChange(device: MediaDeviceInfo) {
    console.log('Ondevice changed: ',device);
    console.log('available devices: ',this.availableDevices);
    console.log('selected device',this.deviceSelected);
    if(this.deviceSelected== ''){
      this.deviceCurrent=null;
    }else{
      this.deviceCurrent= this.availableDevices.find(x => x.deviceId === this.deviceSelected);
    }
    console.log('device current ',this.deviceCurrent);  
  }
  
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    console.log('devices found : ',devices);
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(result){
    console.log('got result ',result);
    if(result.length == 6){
      this.qrResultString= result;
      this.getBarcodeResult(result);
    }
    this.clearResult();


  }

  clearResult(): void {
    this.qrResultString = null;
  }

  
  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  CameraException(error){
    console.log('exception is ',error.name,error);
    if(error.name == 'NotReadableError'){
      this.deviceInUse=true;
    }
  }

  onScanFailure(error){
    console.log(`scan error: ${error}`);
  }

  Showprogress()  
  {  
  this.progress = 0;  
    let interval=setInterval(() => this.ProgressBar(), 1000)  
    this.interval= interval;
    setTimeout(()=> clearInterval(interval),30000);

    }  
   
  ProgressBar() {  
    // console.log('progress is ',this.progress);
    if (this.progress == 0) {  
      this.progress = this.progress + 1  
    } else {  
      this.progress = this.progress + 1;  
      if (this.progress == this.progress + 1) {  
        this.progress = this.progress + 1;  
        if (this.progress >= 30) {  
          this.progress = 30;  
        }  
      }  
    }  
  }  
   setTimer()  
   {  
     var timeleft = 30;  
     var downloadTimer = setInterval( ()=> {  
       if (timeleft <= 0) {  
        console.log('clear interval ',downloadTimer);
        clearInterval(downloadTimer); 
        this.progress = 0;
        // this.alertService.error("Scanner timed out",true);
        this.closeParent.emit({timeout: true});
       } else {  
        console.log('time left ',timeleft);
        
        //  document.getElementById("countdown").innerHTML = timeleft + "";  
       }  
       timeleft -= 1;      
     }, 1000);  
     this.timer= downloadTimer;
    //  setInterval(() => clearInterval(downloadTimer), 31000)  
       
   }  


  public getBarcodeResult(barcode){
    console.log('get barcode result',barcode);
    // this.stopScanning();
   
    this.scannerService.getTranceiverDetails(barcode)
    .subscribe(res=>{
        console.log('got product id and name',res);
        
        this.closeParent.emit(res);
            // this.closeScanner();
            // this.scanner.stop();
    },err=>{
      this.closeParent.emit(err);
        // this.alertService.error(err.error.message,false);
    })
  }

}
