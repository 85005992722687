
<h6 class="filtersheading">Filters Selected</h6>
<div class="col-sm-12 rcoloumn">
    <div class="col-sm-2 left">Showing {{tableData.totalCount}}  Transceiver Product Family Results</div>
    <div *ngIf="!flag" class="smartfilter col-sm-8">
        <div class="right smart" *ngFor="let obj of v">{{obj.name}}<span class="close" (click)="close(obj)">X</span> </div>
    </div>

    <div align="right" class="col-sm-3 rights"><a href="javascript:void(0)" (click)="clearAll();newMessage()">CLEAR ALL FILTERS</a></div>
</div>


<div *ngIf="tableData.totalCount!=0" class=" col-sm-12 ">
    <div class="export">
        <div class="export-excel" title="{{tooltip}}">
            <button  id="exportExcel" type="button" class="btn btn--primary" (click)="saveFile('excel')" [disabled]="disable">
                <span class="icon-file-excel-o icon-small"></span> Export Excel
            </button>
        </div>
        <div *ngIf="!urlValue" class="export-pdf" title="{{tooltip}}">
            <button id="exportPdf" type="file" class="btn btn--primary"  (click)="saveFile('pdf')" [disabled]="disable">
          <span class="icon-file-pdf-o icon-small"></span> Export PDF
        </button>
        </div>
        <div *ngIf="!urlValue" class="export-csv" title="{{tooltip}}">
            <button id="export-csv" type="button" class="btn btn--primary" (click)="saveFile('csv')" [disabled]="disable">
            <span class="icon-file-excel-o icon-small"></span> Export CSV
          </button>
        </div>
    </div>
</div>

<div class="row" *ngIf="tableData.totalCount!=0" >
    <div class="col">
        The Optics-to-Optics Interoperability Matrix only highlights the transceivers&#39; capabilities. These capabilities require software support on network devices, which may not be available in all cases. For more details about network devices/software support, please visit <a (click)="newTab()" style="color: #00bceb;"><b>Optics-to-Device Compatibility Matrix</b></a> or network devices&#39; release notes.<br><br>
        Mouse over<span class="icon-notes icon-small"></span> icon to view notes for other important product information.<br><br>
        Click on <b>Transceiver Product ID</b> to be directed to data sheets

    </div>
</div>


<div  class="page" *ngIf="tableData.totalCount!=0">
    <pagination-controls class="pagination" (pageChange)="page = $event"></pagination-controls>
</div>


<div *ngIf="tableData.totalCount==0">No Results Found</div>


<div class="results col-sm-12" *ngFor="let nd of tableData?.networkDevices |  paginate: { 
    itemsPerPage: 3,
    currentPage: page }">    
    <p headingTable class="tableHead" >{{nd.productFamily}}</p>
    <table class="table table--bordered table--responsive table--wrap" >
        <thead>
            <tr style="background-color: white;">
                <th class="headings" colspan="1"></th>
                <th class="headings" colspan="5" align="center">Transceiver Interoperability</th>
            </tr>
            <tr style="background-color: white;">
                <th style=" text-align: center;background-color: white;">Transceiver Product ID</th>
                <th style=" text-align: center;background-color: white;">Transceiver/Standard</th>
                <th style="text-align: center;background-color: white;">Notes</th>
                <th style="text-align: center;background-color: white;">Interop Reach (up to)</th>   
                <th style="text-align: center;background-color: white;">Links to Patch Cord Information</th>   
                <th style=" text-align: center;background-color: white;">Breakout Patchcord Required</th>
            </tr>
        </thead>
        <ng-container *ngFor="let obj2 of nd.networkAndTransceiverCompatibility;">
            <tbody *ngFor="let obj3 of obj2.transceivers">
                <tr *ngIf="(obj3.transceiverCompatible === null || obj3.transceiverCompatible.length === 0) && (obj3.standardTransceivers === null || obj3.standardTransceivers.length === 0)">
                    <td class="col1" style=" background-color: #fff;"> 
                        <a  *ngIf="obj3.noteCount" (mouseover)="showNotes(obj2.productId,obj3.productId,obj3.productModelId,obj3.networkModelId,obj3.versionId,obj3.releaseId,obj3.noteCount)">
                            <span  [tooltip]="popNotes" placement="right"  id={{obj3.productId}} 
                             class="icon-notes icon-small" containerClass="customClass"></span>
                        </a> 
                        <a *ngIf="obj2.validCueCard" tooltip="Click to Product Cue Card" containerClass="cue" (click)="getProductImage(obj2.productId)" data-toggle="modal" data-target="#ProductImage"> <span class="badge text-bg-custom">CUE</span></a>
                        <a tooltip="Click to Cisco Optics Product Information Matrix or Datasheet" containerClass="copi"   [attr.href]="getCOPIUrl(obj3)" target="_blank">{{obj2.productId || "NA"}}</a> 
                        <a tooltip="Click to Cisco Optics-to-Device Compatibility Matrix or Datasheet" containerClass="tmg" placement="top" [attr.href]="getTmgUrl(obj3)" target="_blank"><span class="icon-rtprx-rtptx-duplex" style="color: white;background-color: #049fd9;"></span></a>
                        <!-- <a (click)="getProductImage(obj2.productId)" data-toggle="modal" data-target="#ProductImage"> <span class="icon-image icon-small"></span></a> -->

                             <p class="endOfSale"    *ngIf="obj3.endOfSale === 'Y'">End Of Sale</p>
                             <p class="breakoutCapable"    *ngIf="obj3.breakoutSupport === 'Y'">Breakout Capable </p>
                       <br>
                       <br>
                    <div class ="row">
                        <div class="col-md-6">
                           <span> Data Rate:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.dataRate || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Form Factor:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.formFactor || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Reach (up to):</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.reach || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Cable Type:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.cableType || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Media:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.media || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Connector Type:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.connectorType || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Case Temp:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.temperatureRange || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>DOM HW Capable:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.domSupport || "NA"}}
                        </div>
                    </div>
                   <br>
                </td>
                    <td class="col2"></td>
                    <td></td>
                    <td class="col3"></td>
                    <td class="col3"></td>
                    <td class="col4" style="text-align: center;background-color: #fff;">{{ obj3.breakoutMode }}</td>
                </tr>
                <tr *ngFor="let tcomp of obj3.transceiverCompatible; let first = first; let last = last">
                    <td *ngIf="first" class="col1"  [attr.rowspan]=obj3.transceiverCompatible.length+obj3.standardTransceivers.length style=" background-color: #fff;"> 
                        <a  *ngIf="obj3.noteCount" (mouseover)="showNotes(obj2.productId,obj3.productId,obj3.productModelId,obj3.networkModelId,obj3.versionId,obj3.releaseId,obj3.noteCount)">
                            <span  [tooltip]="popNotes" placement="right"  id={{obj3.productId}} 
                            class="icon-notes icon-small" containerClass="customClass"></span>
                        </a> 
                        <a *ngIf="obj2.validCueCard" tooltip="Click to Product Cue Card" containerClass="cue" (click)="getProductImage(obj2.productId)" data-toggle="modal" data-target="#ProductImage"> <span class="badge text-bg-custom">CUE</span></a>
                        <a tooltip="Click to Cisco Optics Product Information Matrix or Datasheet" containerClass="copi"   [attr.href]="getCOPIUrl(obj3)" target="_blank">{{obj2.productId || "NA"}}</a> 
                        <a tooltip="Click to Cisco Optics-to-Device Compatibility Matrix or Datasheet" containerClass="tmg" placement="top" [attr.href]="getTmgUrl(obj3)" target="_blank"><span class="icon-rtprx-rtptx-duplex" style="color: white;background-color: #049fd9;"></span></a>

                        <p class="endOfSale"    *ngIf="obj3.endOfSale === 'Y'">End Of Sale</p>
                        <p class="breakoutCapable"    *ngIf="obj3.breakoutSupport === 'Y'">Breakout Capable </p>
                       <br>
                       <br>
                    <div class ="row">
                        <div class="col-md-6">
                           <span> Data Rate:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.dataRate || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Form Factor:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.formFactor || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Reach (up to):</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.reach || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Cable Type:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.cableType || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Media:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.media || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Connector Type:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.connectorType || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Case Temp:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.temperatureRange || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>DOM HW Capable:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.domSupport || "NA"}}
                        </div>
                    </div>
                   <br>
                </td>
                <td class="col2">
                        <div class="row">
                            <!-- <div class="col-md-1">
                                <div class = "notesdiv" *ngIf = " obj3.transCompWithNotes !== null">
                                <a *ngIf="obj3.transCompWithNotes[tcomp] !== null && obj3.transCompWithNotes[tcomp] !== undefiened  "   (mouseover)="showMiniNotes(obj3.transCompWithNotes[tcomp])">
                                    <span  [tooltip]="notes" placement="right" id={{obj3.productId}} 
                                     class="icon-notes icon-small" containerClass="nextCustomClass"></span>
                                     </a>
                                     </div>
                                     <div *ngIf = " obj3.transCompWithNotes === null">
                                        <a *ngIf="obj3.transCompWithNotes[tcomp] === null && obj3.transCompWithNotes[tcomp] !== undefiened  " >
                                            <span  [tooltip]="notes" placement="right" id={{obj3.productId}} 
                                             class="icon-notes icon-small" containerClass="nextCustomClass" style="background-color: transparent"></span>
                                             </a>
                                             </div>
                            </div> -->
                            <div class="col-md-10">
                                  <span>{{tcomp}}</span>
                            </div>
                           
                          
                        </div>
                        <br>
                        
                      
                </td>
                <td class="col5"> {{obj3.transCompWithNotes[tcomp] || ""}} </td>
                <td class="col3">
                                      <span>{{obj3.transCompWithReach[tcomp] || ""}}</span>
                                      
                            <br>
                     
                 
                    
                </td>
                <td class="col3">  <a href="{{getLink(obj3.transCompIopLink[tcomp])}}" target="_blank">{{getText(obj3.transCompIopLink[tcomp])}}</a> </td>  
                <td class="col4"  *ngIf="first"  [attr.rowspan]=obj3.transceiverCompatible.length+obj3.standardTransceivers.length style="text-align: center;background-color: #fff;">{{ obj3.breakoutMode }}</td>  
                </tr>
                <tr *ngFor="let stcomp of obj3.standardTransceivers; let firststc = first; let laststc = last">
                    <td *ngIf="(obj3.transceiverCompatible.length === 0 || obj3.transceiverCompatible === null) && firststc" class="col1" [attr.rowspan]=obj3.transceiverCompatible.length+obj3.standardTransceivers.length style="background-color: #fff;"> 
                        <a *ngIf="obj3.noteCount" (mouseover)="showNotes(obj2.productId,obj3.productId,obj3.productModelId,obj3.networkModelId,obj3.versionId,obj3.releaseId,obj3.noteCount)">
                            <span  [tooltip]="popNotes" placement="right" id={{obj3.productId}} 
                            class="icon-notes icon-small" containerClass="customClass">
                            </span>
                        </a> 
                        <a *ngIf="obj2.validCueCard"  tooltip="Click to Product Cue Card" containerClass="cue" (click)="getProductImage(obj2.productId)" data-toggle="modal" data-target="#ProductImage"> <span class="badge text-bg-custom">CUE</span></a>
                        <a tooltip="Click to Cisco Optics Product Information Matrix or Datasheet" containerClass="copi"   [attr.href]="getCOPIUrl(obj3)" target="_blank">{{obj2.productId || "NA"}}</a> 
                        <a tooltip="Click to Cisco Optics-to-Device Compatibility Matrix or Datasheet" containerClass="tmg" placement="top" [attr.href]="getTmgUrl(obj3)" target="_blank"><span class="icon-rtprx-rtptx-duplex" style="color: white;background-color: #049fd9;"></span></a>
                        <p class="endOfSale"    *ngIf="obj3.endOfSale === 'Y'">End Of Sale</p>
                        <p class="breakoutCapable"    *ngIf="obj3.breakoutSupport === 'Y'">Breakout Capable </p>
                       <br>
                       <br>
                    <div class ="row">
                        <div class="col-md-6">
                           <span> Data Rate:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.dataRate || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Form Factor:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.formFactor || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Reach (up to):</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.reach || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Cable Type:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.cableType || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Media:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.media || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Connector Type:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.connectorType || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>Case Temp:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.temperatureRange || "NA"}}
                        </div>
                    </div>
                    <div class ="row">
                        <div class="col-md-6">
                            <span>DOM HW Capable:</span>
                        </div>
                        <div class="col-md-6">
                            {{obj3.domSupport || "NA"}}
                        </div>
                    </div>
                   <br>
                </td>
                <td class="col2">
                    <div class="row">
                        <!-- <div class="col-md-1">
                            <div class = "notesdiv" *ngIf = " obj3.transCompWithNotes !== null">
                            <a *ngIf="obj3.transCompWithNotes[stcomp] !== null && obj3.transCompWithNotes[stcomp] !== undefiened  "   (mouseover)="showMiniNotes(obj3.transCompWithNotes[stcomp])">
                                <span  [tooltip]="notes" placement="right" id={{obj3.productId}} 
                                 class="icon-notes icon-small" containerClass="nextCustomClass"></span>
                                 </a>
                                 </div>
                                 <div *ngIf = " obj3.transCompWithNotes === null">
                                    <a *ngIf="obj3.transCompWithNotes[stcomp] === null && obj3.transCompWithNotes[stcomp] !== undefiened  " >
                                        <span  [tooltip]="notes" placement="right" id={{obj3.productId}} 
                                         class="icon-notes icon-small" containerClass="nextCustomClass" style="background-color: transparent"></span>
                                         </a>
                                         </div>
                        </div> -->
                        <div class="col-md-10">
                              <span>{{stcomp}}</span>
                        </div>
                       
                      
                    </div>
                    <br>
                        
                      

                </td>
                <td class="col5"> {{obj3.transCompWithNotes[stcomp] || ""}} </td>
                <td class="col3">
                    <span>{{obj3.transCompWithReach[stcomp] || ""}}</span>
                                      
                            <br>
                     
                    
                </td>
                <td class="col3">  <a href="{{getLink(obj3.transCompIopLink[stcomp])}}" target="_blank">{{getText(obj3.transCompIopLink[stcomp])}}</a> </td>  

                <td class="col4" *ngIf="(obj3.transceiverCompatible.length === 0 || obj3.transceiverCompatible === null) && firststc"  [attr.rowspan]=obj3.transceiverCompatible.length+obj3.standardTransceivers.length style="text-align: center;background-color: #fff;">{{ obj3.breakoutMode }}</td>  

                </tr>
    
    </tbody>
        </ng-container>
        
    
    </table> 
</div>


<div  class="page" *ngIf="tableData.totalCount!=0">
    <pagination-controls class="pagination" (pageChange)="page = $event"></pagination-controls>

</div>

<ng-template #popNotes >
    <div class="notess" >
         <h3 >Notes  </h3><br>
                <div *ngIf="noteResult[0] != undefiened">
                    <ul *ngIf="noteResult[0] != undefiened">
                        <ng-container *ngFor="let nd of noteResult[0]">
                            <li *ngIf="nd!=''">{{nd}}</li>
                        </ng-container>
                    </ul>
                    </div>
                    <div *ngIf="noteResult[1] != undefiened">
                    <ul *ngIf="noteResult[1] != undefiened"> 
                        <ng-container *ngFor="let tm of noteResult[1]">
                            <li *ngIf="tm!=''">{{tm}}</li>
                        </ng-container>
                    </ul>
                    </div>
                    <div *ngIf = "noteResults !== null && noteResults !== undefiened ">
                        <ul *ngIf="noteResults != undefiened">
                            <li >{{noteResults}}
                            </li>
                        </ul>
                    </div>
            </div>
                            
</ng-template>

<ng-template #notes>
    <div class="notesData" >
        <h3 >Notes  </h3><br>
                   <div *ngIf = "noteResults !== null && noteResults !== undefiened ">
                       <ul *ngIf="noteResults != undefiened">
                           <li >{{noteResults}}
                           </li>
                       </ul>
                   </div>
           </div>
</ng-template>

<div class="modal" id="ProductImage" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
            <span></span>
            <h4 class="modal-title">Product Image</h4>
            <button style="margin: 0px;" type="button" class="close" data-dismiss="modal"><span aria-hidden="true" class="icon-close icon-medium"></span></button>
        </div>
        <div class="modal-body">
            <span *ngIf="imageError!=''">
                <p> {{imageError}} </p>
            </span>
             <span *ngIf="productImage">
                <img [src]="productImage" />
             </span>
            <!-- <img > -->
        </div>
      </div>
    </div>
</div>