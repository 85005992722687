import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteropAppComponent } from '../interop/interop-component/interop-app/interop-app.component';
import { RouterModule } from '@angular/router';
import { InteropAdminBannerComponent } from '../interop/interop-component/interop-adminbanner/interop-adminbanner.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { InteropHomeComponent } from '../interop/interop-component/interop-home/interop-home.component';
import { FormsModule } from '@angular/forms';
import { InteropFiltersComponent } from '../interop/interop-component/interop-filters/interop-filters.component';
// import { InteropHeaderComponent } from '../interop/interop-component/interop-header/interop-header.component';
import { InteropFilterpipePipe } from '../interop/interop-component/interop-filters/interop-filterpipe.pipe';
import { InteropTableComponent } from '../interop/interop-component/interop-table/interop-table.component';
import { InteropFooterComponent } from '../interop/interop-component/interop-footer/interop-footer.component';
import { InteropSuggestPipe } from '../interop/interop-component/interop-home/interop-suggestion-pipe.pipe';
import { InteropNospacePipe } from '../interop/interop-component/interop-filters/interop-nospace.pipe';
import { InteropCapitalizePipe } from '../interop/interop-component/interop-home/interop-capitalize.filter';
import { InteropSplitCamelCase } from '../interop/interop-component/interop-home/interop-splitcamelcase.pipe';
import { InteropArraySortPipe } from '../interop/interop-component/interop-filters/interop-filtersort.pipe';
import { InteropFilterPipe } from '../interop/interop-component/interop-filters/interop-filter.pipe';
import { InteropEmptyComponent } from '../interop/interop-component/interop-empty/interop-empty.component';
import { InteropLogoutComponent } from '../interop/interop-component/interop-logout/interop-logout.component';
import { InteropSuggesionService } from '../interop/interop-service/interop-autoSuggestion.service';
import { InteropSingleService } from '../interop/interop-service/interop-singleSearch.service';
import { InteropDataService } from '../interop/interop-service/interop-shared.service';
import { InteropSpinnerService } from '../interop/interop-service/interop-spinner.service';
import { InteropMessageService } from '../interop/interop-service/interop-message.service';
import { InteropGetURL } from '../interop/interop-service/interop-gettingUrl.service';
import { InteropFirstService } from '../interop/interop-service/interop-first.service';
import { InteropBannerService } from '../interop/interop-service/interop-banner.service';
import { InteropAdminBannerService } from '../interop/interop-service/interop-adminbanner.service';
import { InteropRouteInformationService } from '../interop/interop-service/interop-routeInformation.service';
import { InteropNavigateService } from '../interop/interop-service/interop-navigate.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule, ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { WebScannerComponent } from '../component/web-scanner/web-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScannerService } from '../service/scanner.service';
import { CookieService } from 'ngx-cookie-service';
import { TabsModule } from 'ngx-bootstrap/tabs';



@NgModule({
  providers: [
    ScannerService,
    CookieService,
    InteropSuggesionService,InteropSingleService,InteropDataService,
    InteropSpinnerService,InteropMessageService,InteropGetURL,
    InteropFirstService,InteropBannerService,InteropAdminBannerService,
     InteropRouteInformationService, InteropNavigateService,
     ProgressbarConfig

  ],
  declarations: [

    InteropFilterpipePipe,InteropHomeComponent,InteropFiltersComponent,
    InteropTableComponent,InteropFooterComponent,
    InteropSuggestPipe,InteropNospacePipe,InteropCapitalizePipe, InteropSplitCamelCase ,
    InteropArraySortPipe,InteropFilterPipe,InteropEmptyComponent,InteropLogoutComponent
    ,InteropAppComponent,
    InteropAdminBannerComponent,
    SafeHtmlPipe,
    WebScannerComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    RouterModule,
    FormsModule,
    ZXingScannerModule,
    ProgressbarModule,
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
  ],
  exports: [
    InteropFilterpipePipe,InteropHomeComponent,InteropFiltersComponent,
    InteropTableComponent,InteropFooterComponent,
    InteropSuggestPipe,InteropNospacePipe,InteropCapitalizePipe, InteropSplitCamelCase ,
    InteropArraySortPipe,InteropFilterPipe,InteropEmptyComponent,InteropLogoutComponent
    ,InteropAppComponent,
    InteropAdminBannerComponent,
    NgxPaginationModule,
    CarouselModule,
    TooltipModule,
    SafeHtmlPipe,
    WebScannerComponent,
    TabsModule
  ]
})
export class SharedModule { }
