import { Injectable } from '@angular/core';

@Injectable()
export class InteropSpinnerService {
    private inputValue: boolean;

    constructor() { 
        this.inputValue = false;
       }

       public setdisplayValue(val: boolean) {
       
         this.inputValue = val;
    }
    public getdisplayValue(): boolean {
        return this.inputValue;
    }

}