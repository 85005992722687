import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, ViewChildren, OnChanges } from '@angular/core';
import { AfterViewChecked } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaderResponse, HttpErrorResponse } from '@angular/common/http';
import{ InteropGetURL } from '../../interop-service/interop-gettingUrl.service';
import {Observable} from "rxjs";
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { InteropAdminBannerService} from '../../interop-service/interop-adminbanner.service';
import { InteropSpinnerService } from '../../interop-service/interop-spinner.service';

@Component({
  selector: 'interopapp-adminbanner',
  templateUrl: './interop-adminbanner.component.html',
  styleUrls: ['./interop-adminbanner.component.scss']
})


export class InteropAdminBannerComponent implements OnInit {
    
    private showList : boolean;
    public uploadChange: boolean;
    public banners: any;
    private formdata: FormData;
    private fileresponse: any;
    public fileName = null;
    private selectedFiles: FileList;
    private currentFile: File;
    title: string;
    description: string;
    private id: string;
    private enabled: string;
    imageUrl: string;
    private image_blob: string;
    private activeClass: boolean= true;
    public timeDelay: string;
    public getTimeDelay: string;
    public showSuccessAdd: boolean;
    public showSuccessEdit: boolean;
    public showSuccessDelete: boolean;
    private tempTitle: string;
    private addFlag: boolean;
    private editFlag: boolean;
    private deleteFlag: boolean;
    public disable: boolean;
    private alertTitle: String;
    public filesizeFlag: boolean;
    public fileuploadFlag: boolean;
    
    constructor(private adminbanners: InteropAdminBannerService, private spin: InteropSpinnerService) {
      this.showList = true; 
      this.uploadChange = false; 
      this.disable = true;
      this.showSuccessAdd = false;
      this.showSuccessEdit = false;
      this.showSuccessDelete = false;
      this.filesizeFlag = false;
      this.fileuploadFlag = false;
    }



    ngOnInit() {
      this.getBanners();
      this.getterTimeDelay();
      }


    getBanners(){
        this.adminbanners.getBanners().subscribe( res => {
            this.banners = res;
            this.spin.setdisplayValue(false);
            if(this.addFlag){
                this.alertTitle = this.tempTitle;
                this.showSuccessEdit = false;
                this.showSuccessDelete = false;
                this.showSuccessAdd = true;
                this.addFlag = false;
            }

            else if(this.editFlag){
                this.alertTitle = this.tempTitle;
                this.showSuccessAdd = false;
                this.showSuccessDelete = false;
                this.showSuccessEdit = true;
                this.editFlag = false;
            }

            else if(this.deleteFlag){
                this.alertTitle = this.tempTitle;
                this.showSuccessAdd = false;
                this.showSuccessEdit = false;
                this.showSuccessDelete = true;
                this.deleteFlag = false;
            }

            this.title = null;
            this.description = null;
            this.id = null;
            this.enabled = null;
            this.imageUrl = null;
            this.image_blob = null;
            this.selectedFiles = null;
            this.currentFile = null;
            this.fileName = null;
            this.uploadChange = false;            
        })
    }

    getterTimeDelay(){
        this.adminbanners.getBannerTimeDelay().subscribe( (res:any) =>{
            this.getTimeDelay = res;
            this.spin.setdisplayValue(false);
        })
    }
    
    toggle1()
    {
      this.title = null;
      this.description = null;
      this.id = null;
      this.enabled = null;
      this.imageUrl = null;
      this.image_blob = null;
      this.currentFile = null;
      this.fileName = null;
      this.selectedFiles = null;
    
      this.showList = true;
      this.uploadChange = false;
      this.timeDelay = null;
      this.filesizeFlag = false;
      this.fileuploadFlag = false;
    }
    
    toggle2()
    {
        this.activeClass = false;
      this.title = null;
      this.description = null;
      this.id = null;
      this.enabled = null;
      this.imageUrl = null;
      this.image_blob = null;
      this.currentFile = null;
      this.fileName = null;
      this.selectedFiles = null;
  
      this.showList = false;
      this.uploadChange = false;
      this.timeDelay = null;
      this.filesizeFlag = false;
      this.fileuploadFlag = false;
    }
    
    populate(banner:any){
      this.title = banner.title;
      this.description = banner.description;
      this.id = banner.id;
      this.enabled = banner.enabled;
      this.imageUrl = banner.imageUrl;
      this.image_blob = banner.image_blob;
      this.filesizeFlag = false;

      this.disable = false;
      this.fileuploadFlag = true;
    }
    
    chooseFile(){
      document.getElementById("uploadImage1").click();
      }
  
    chooseFile2(){
      document.getElementById("uploadImage2").click();
      }
      
      
    selectFile(event){
        this.selectedFiles = null;
        this.uploadChange = true;
        this.selectedFiles = event.target.files;
        if(this.selectedFiles[0].size<=5242880){
            this.fileuploadFlag = true;
            this.fileName = this.selectedFiles[0].name;
            this.filesizeFlag = false;
        }
        else {
            this.fileuploadFlag = false;
            this.fileName = null;
            this.filesizeFlag = true;
        }
      }
      
    closeRes(event: string){
        if(event == "success"){
            this.showSuccessAdd=false;
            this.showSuccessEdit = false;
            this.showSuccessDelete = false;
            this.addFlag = false;
            this.editFlag = false;
            this.deleteFlag = false;
        }
    }

    uploadFile(){
        this.currentFile = this.selectedFiles[0];
        this.formdata = new FormData();
        this.formdata.append("mpFile",this.currentFile);
        this.formdata.append("id","");
        this.formdata.append("imageUrl",this.imageUrl);
        this.formdata.append("title",this.title);
        this.formdata.append("description",this.description);
        this.formdata.append("image_blob","");
        this.formdata.append("enabled","");
        this.tempTitle = this.title;
        this.spin.setdisplayValue(true);
        this.addFlag  = true;
        this.adminbanners.addBanner(this.formdata).subscribe(res =>{
            this.fileresponse = res;
            this.getBanners();
      });
    }

    editFile(){
        if(this.selectedFiles!=null){
        this.currentFile = this.selectedFiles[0];
        }
        this.formdata = new FormData();
        this.formdata.append("mpFile",this.currentFile);
        this.formdata.append("id",this.id);
        this.formdata.append("imageUrl",this.imageUrl);
        this.formdata.append("title",this.title);
        this.formdata.append("description",this.description);
        this.formdata.append("image_blob","");
        this.formdata.append("enabled","");
        this.tempTitle = this.title;
        this.spin.setdisplayValue(true);
        this.editFlag = true;
        this.adminbanners.editBanner(this.formdata).subscribe(res =>{
            //this.spin.setdisplayValue(false);
            this.fileresponse = res;
            this.getBanners();

      });
    }


    deleteFile(id:string, title:string){
        this.formdata = new FormData();
        this.formdata.append("id",id);
        this.spin.setdisplayValue(true);
        this.tempTitle = title;
        this.deleteFlag = true;
        this.adminbanners.deleteBanner(this.formdata).subscribe(res => {
            this.fileresponse = res;
            this.getBanners();
            // window.location.reload();
        })
    }

    setBannerTimeDelay(){
        this.formdata = new FormData();
        this.formdata.append("timeDelay",this.timeDelay);
        this.spin.setdisplayValue(true);
        this.adminbanners.setBannerTimeDelay(this.formdata).subscribe(res => {
            this.fileresponse = res;
            this.timeDelay = null;
            this.getterTimeDelay();
            // window.location.reload();
        })

    }

    blankSpaceCheck(input: String){
        if(input!=null){
        input = input.replace(/\s/g, '');
        if(input=="")
            this.disable = true;
        else
            this.disable = false;
        }
    }
  
}