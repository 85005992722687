import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'splitCamelCase' })
export class SplitCamelCase implements PipeTransform{
      transform(text: string){
              var l =text.length;
              if(text == "ostype"){
                    return "OS Type";
              }
              else{
              return text.charAt(0).toUpperCase()+text.slice(1,l-1).split(/(?=[A-Z])/).join(' ')+text.slice(l-1);
              }
      }
}